/* eslint-disable react-hooks/exhaustive-deps */
import { Card, FormHelperText, Grid, ImageList, ImageListItem, SxProps, Theme, styled } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { CustomFile } from './type';
import { fData } from 'src/utils/formatNumber';
import { useDropzone } from 'react-dropzone';
import Iconify from '../base/Iconify';
import RejectionFiles from './RejectionFiles';
import LazyImage from '../base/LazyImage';

// ----------------------------------------------------------------------

export interface FileWithPreview extends File {
    preview: string;
}

type UploadProps = {
    uploadFiles: FileWithPreview[];
    setUploadFiles: (arg0: FileWithPreview[] | ((prevFiles: FileWithPreview[]) => FileWithPreview[])) => void;
    multiple?: boolean;
}

// ----------------------------------------------------------------------

export default function UploadAvatarComp({ uploadFiles, setUploadFiles, multiple }: UploadProps) {

    const handleDrop = useCallback((acceptedFiles: File[]) => {
        const newFiles = acceptedFiles.map(file => Object.assign(file, {
            preview: URL.createObjectURL(file)
        }));
        setUploadFiles((prevFiles: FileWithPreview[]) => [...prevFiles, ...newFiles]);
    }, []);

    return (
        <UploadAvatar
            files={uploadFiles}
            maxSize={3145728}
            onDrop={handleDrop}
            removeFile={(file) => setUploadFiles(uploadFiles.filter(f => f !== file))}
            multiple={multiple}
        />
    );
};

// ----------------------------------------------------------------------

type UploadAvatarProps = {
    files: CustomFile[] | string[];
    maxSize: number;
    onDrop: (acceptedFiles: File[]) => void;
    removeFile: (file: CustomFile | string) => void;
    multiple?: boolean;
    sx?: SxProps<Theme>;
};
  
// ----------------------------------------------------------------------
  
function UploadAvatar({ files, maxSize, onDrop, removeFile, multiple, sx, ...other }: UploadAvatarProps) {

    const [error, setError] = useState(false);
    const [helperText, setHelperText] = useState('');

    useEffect(() => {
        setHelperText(`Allowed: *.jpeg, *.jpg, *.png\n Max size: ${fData(maxSize)}`);
    }, [maxSize]);

    const { getRootProps, getInputProps, isDragActive, isDragReject, fileRejections } = useDropzone({
        multiple: multiple,
        onDropAccepted: (acceptedFiles) => {
            setError(false);
            setHelperText('');
            onDrop(acceptedFiles);
        },
        onDropRejected: () => {
            setError(true);
            setHelperText('Invalid file type or size');
        },
        ...other,
    });

    const grid_sizing = {
        xs: 12,
        sm: 12,
        md: 6,
        lg: 6,
        xl: 6,
    }

    return (
        <>
            <Grid container spacing={2} justifyContent="center" alignItems="center" sx={{ p: 1, width: '100%' }}>
                <Grid item {...grid_sizing}>
                    <Card
                        sx={{
                            ...((isDragReject || error) && {
                                borderColor: 'error.light',
                            }),
                            width: '100%',
                            height: '200px',
                            ...sx,
                        }}
                    >
                        <DropZoneStyle
                            {...getRootProps()}
                            sx={{
                                ...(isDragActive && { opacity: 0.72 }),
                                // borderRadius: '4%',
                            }}
                        >
                            <input {...getInputProps()} />

                            {!multiple && files.length === 1 && (
                                <LazyImage
                                    alt="main_image"
                                    src={isString(files[0]) ? files[0] : files[0].preview}
                                    sx={{ zIndex: 8 }}
                                />
                            )}
                            {multiple && files.length > 0 && (
                                <ImageList cols={3} sx={{ zIndex: 8 }}>
                                    {files.map((file, index) => {
                                        const file_src = isString(file) ? file : file.preview;
                                        const file_name = isString(file) ? file : file.name;
                                        return (
                                            <ImageListItem key={file_name + `_image_${index}`}>
                                                <LazyImage
                                                    alt={file_name}
                                                    src={file_src}
                                                    sx={{ zIndex: 8 }}
                                                />
                                            </ImageListItem>
                                        )
                                    })}
                                </ImageList>
                            )

                            }

                            <PlaceholderStyle
                                className="placeholder"
                                sx={{
                                    ...(files.length > 0 && {
                                        opacity: 0,
                                        '&:hover': { opacity: 0.72 },
                                    }),
                                }}
                            >
                                <Iconify icon={'mdi:file-document-add-outline'} sx={{ width: 24, height: 24, mb: 1 }} />
                            </PlaceholderStyle>
                        </DropZoneStyle>
                    </Card>

                    { helperText && (
                        <FormHelperText error={error} sx={{ textAlign: 'center' }}>
                            {helperText}
                        </FormHelperText>
                    )}
                </Grid>

                { fileRejections.length > 0 &&
                    <Grid item xs={12}>
                        <RejectionFiles fileRejections={fileRejections} />
                    </Grid>
                }
            </Grid>
        </>
    );
};

// ----------------------------------------------------------------------

export function isString(file: CustomFile | string): file is string {
    return typeof file === 'string';
};

const RootStyle = styled('div')(({ theme }) => ({
    margin: 'auto',
    padding: theme.spacing(1),
}));

const DropZoneStyle = styled('div')({
    zIndex: 0,
    width: '100%',
    height: '100%',
    outline: 'none',
    display: 'flex',
    overflow: 'hidden',
    position: 'relative',
    alignItems: 'center',
    justifyContent: 'center',
    '& > *': { width: '100%', height: '100%' },
    '&:hover': {
        cursor: 'pointer',
        '& .placeholder': {
            zIndex: 9,
        },
    },
});

const PlaceholderStyle = styled('div')(({ theme }) => ({
    display: 'flex',
    position: 'absolute',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.background.neutral,
    transition: theme.transitions.create('opacity', {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.shorter,
    }),
    '&:hover': { opacity: 0.72 },
}));