import { useEffect, useState } from 'react';
import { Stack, Alert, TextField, Link, Typography, InputAdornment, IconButton, Button } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { PATH_GUEST } from 'src/routes/paths';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { useApiCall } from 'src/hooks/useApiCall';
import Iconify from 'src/components/base/Iconify';

// ----------------------------------------------------------------------

export default function ResetForm() {
    const location = useLocation();

    const [uid, setUid] = useState<string>('');
    const [token, setToken] = useState<string>('');

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        setUid(queryParams.get('uid') || '');
        setToken(queryParams.get('token') || '');
    }, [location.search]);

    return (
        <Stack spacing={3}>
            {(uid && token) ?
                <HandleReset uid={uid} token={token} />
                : <RequestReset />
            }
        </Stack>
    );
}

// ----------------------------------------------------------------------

function RequestReset() {
    const { apiCall } = useApiCall();
    const [error, setError] = useState<string | null>(null);
    const [email, setEmail] = useState<string>('');
    const [emailSent, setEmailSent] = useState<boolean>(false);
    const [awaitingResponse, setAwaitingResponse] = useState<boolean>(false);

    const onSubmit = async () => {
        if (email === '') {
            setError('Email is required');
            return;
        }
        setError(null);
        setAwaitingResponse(true);
        const getFormData = () => {
            const formData = new FormData();
            formData.append('email', email);
            return formData;
        };
        await apiCall('request_reset_password/', "POST", getFormData(), (data) => {
            if (data.success) { setEmailSent(true) };
            setAwaitingResponse(false);
        });
    };
    return (
        <>
            {emailSent ?
                <Stack justifyContent={'center'} alignItems={'center'} spacing={2} sx={{ py: 6 }}>
                    <Iconify
                        icon={'line-md:check-all'}
                        width={80}
                        height={80}
                    />
                    <Typography variant="h4" align="center">
                        Email Sent
                    </Typography>
                    <Typography variant="body2" align="center">
                        Please check your email for further instructions.
                    </Typography>
                </Stack> :
                <>
                    <Stack spacing={3}>
                        {error && <Alert severity="error">{error}</Alert>}

                        <TextField
                            name="email"
                            label="Email"
                            variant="outlined"
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <LoadingButton
                            fullWidth
                            size="large"
                            type="submit"
                            variant="contained"
                            loading={awaitingResponse}
                            onClick={onSubmit}
                        >
                            Send Reset Email
                        </LoadingButton>
                    </Stack>
                    <Stack direction="row" alignItems="center" justifyContent="center" sx={{ my: 2 }}>
                        <Link component={RouterLink} variant="subtitle2" to={PATH_GUEST.signup}>
                        Sign Up
                        </Link>
                    </Stack>
                    <Stack direction="row" alignItems="center" justifyContent="center" sx={{ my: 2 }}>
                        <Link component={RouterLink} variant="subtitle2" to={PATH_GUEST.login}>
                            Login
                        </Link>
                    </Stack>
                </>
            }
        </>
    );
}

// ----------------------------------------------------------------------

type HandleResetProps = {
    uid: string;
    token: string;
};

function HandleReset({ uid, token }: HandleResetProps) {
    
    const { apiCall } = useApiCall();

    const [newPassword, setNewPassword] = useState<string>('');
    const [confirmPassword, setConfirmPassword] = useState<string>('');
    const [error, setError] = useState<string | null>(null);
    const [awaitingResponse, setAwaitingResponse] = useState<boolean>(false);
    const [success, setSuccess] = useState<boolean>(false);
    const [showPassword, setShowPassword] = useState(false);

    const changePassword = async () => {
        if (!uid || !token || !newPassword) { return };
        setAwaitingResponse(true);
        const formData = new FormData();
        formData.append('uid', uid);
        formData.append('token', token);
        formData.append('new_password', newPassword);

        await apiCall('reset_password/', "POST", formData, (data) => {
            setSuccess(true);
            localStorage.setItem('accessToken', data.access);
            localStorage.setItem('refreshToken', data.refresh);
        }).catch((error) => {
            console.log(error);
            setAwaitingResponse(false);
            setSuccess(false);
            setError('An error occurred. Please try again.');
        });
    };

    useEffect(() => {
        if (newPassword.length < 8) {
            setError('Password must be at least 8 characters');
        }
        if (confirmPassword === '') {
            setError('Please confirm your password');
        }
        if (newPassword !== confirmPassword && confirmPassword !== '') {
            setError('Passwords do not match');
        } else {
            setError(null);
        }
    }, [newPassword, confirmPassword]);

    return (
        <>
            {success ?
                <Stack justifyContent={'center'} alignItems={'center'} spacing={2} sx={{ py: 6 }}>
                    <Iconify
                        icon={'line-md:check-all'}
                        width={80}
                        height={80}
                    />
                    <Typography variant="h4" align="center">
                        Password Reset
                    </Typography>
                    <Typography variant="body2" align="center">
                        Your password has been reset.
                    </Typography>
                    <Stack direction="row" alignItems="center" justifyContent="center" sx={{ my: 2 }}>
                        <Button component={RouterLink} variant={'contained'} to={PATH_GUEST.login}>
                            Login
                        </Button>
                    </Stack>
                </Stack> :
                <Stack spacing={3}>
                    {error && <Alert severity="error">{error}</Alert>}

                    <TextField
                        name="new_password"
                        label="New Password"
                        type={showPassword ? 'text' : 'password'}
                        variant="outlined"
                        onChange={(e) => setNewPassword(e.target.value)}
                        InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                            <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                            </IconButton>
                            </InputAdornment>
                        ),
                        }}
                    />
                    <TextField
                        name="confirm_password"
                        label="Confirm Password"
                        type={showPassword ? 'text' : 'password'}
                        variant="outlined"
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                                </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                    <LoadingButton
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        loading={awaitingResponse}
                        onClick={changePassword}
                    >
                        Reset Password
                    </LoadingButton>
                </Stack>
            }
        </>
    )
}