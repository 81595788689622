// @mui
import { Container, Stack } from '@mui/material';
// components
import Page from "src/components/base/Page";
// sections
import { LoginForm } from 'src/auth/login';
import { LANDING_PAGES, MAIN_LOGO } from 'src/config';

// ----------------------------------------------------------------------

export default function Login() {

  return (
    <Page title="Login">
      <Container maxWidth="sm">
        <Stack sx={{ justifyContent: 'center', alignItems: 'center', my: 2 }} direction="row" spacing={1}>
            <img
              src={MAIN_LOGO}
              alt="IS Logo"
              onClick={() => {
                window.location.href = LANDING_PAGES.home;
              }}
              style={{ cursor: 'pointer' }}
            />
        </Stack>

        <LoginForm />

      </Container>
    </Page>
  );
}