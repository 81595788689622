import { m } from 'framer-motion';
import { forwardRef, ReactNode } from 'react';
// @mui
import { Box, IconButton, IconButtonProps, keyframes } from '@mui/material';

// ----------------------------------------------------------------------

const IconButtonAnimate = forwardRef<HTMLButtonElement, IconButtonProps & { pulse?: boolean, shake?: boolean }>(
  ({ children, size = 'medium', pulse = false, shake = false, ...other }, ref) => (
    <AnimateWrap size={size} pulse={pulse} shake={shake}>
      <IconButton size={size} ref={ref} {...other}>
        {children}
      </IconButton>
    </AnimateWrap>
  )
);

export default IconButtonAnimate;

// ----------------------------------------------------------------------

type AnimateWrapProp = {
  children: ReactNode;
  size: 'small' | 'medium' | 'large';
  pulse?: boolean;
  shake?: boolean;
};

const varSmall = {
  hover: { scale: 1.1 },
  tap: { scale: 0.95 },
};

const varMedium = {
  hover: { scale: 1.09 },
  tap: { scale: 0.97 },
};

const varLarge = {
  hover: { scale: 1.08 },
  tap: { scale: 0.99 },
};

function AnimateWrap({ size, children, pulse, shake }: AnimateWrapProp) {
  const isSmall = size === 'small';
  const isLarge = size === 'large';

  return (
    <Box
      component={m.div}
      whileTap="tap"
      whileHover="hover"
      variants={(isSmall && varSmall) || (isLarge && varLarge) || varMedium}
      sx={{
        display: 'inline-flex',
        ...(pulse && {
          animation: `${getPulse()} 2s ease-in-out infinite`,
        }),
        ...(shake && {
          animation: `${getShake()} 2.5s ease-in-out infinite`,
        }),
      }}
    >
      {children}
    </Box>
  );
}

function getPulse() {
  return keyframes({
    '0%': {
      transform: 'scale(0.95)',
      opacity: 1,
    },
    '50%': {
      transform: 'scale(1.15)',
      opacity: 0.5,
    },
    '100%': {
      transform: 'scale(0.95)',
      opacity: 1,
    },
  });
}

function getShake() {
  return keyframes({
    '0%, 100%': {
      transform: 'translate(0%, 0%)',
    },
    '5%, 15%': {
      transform: 'translate(0%, 0%) rotate(25deg)',
    },
    '10%, 20%': {
      transform: 'translate(0%, 0%) rotate(-25deg)',
    },
    '25%': {
      transform: 'translate(0%, 0%) rotate(0deg)',
    }
  });
}