import { Fade, Grid, IconButton, ToggleButton, ToggleButtonGroup, Tooltip } from "@mui/material"
import { useLocation } from "react-router-dom";
import queryString from 'query-string';

import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

// ----------------------------------------------------------------------

type SortButtonsProps = {
    updateQueryParams: (params: { sort: string }) => void;
};

export default function SortButtons({ updateQueryParams }: SortButtonsProps) {

    const location = useLocation();

    const handleSortChange = (event: React.MouseEvent<HTMLElement>, sortOrder: 'asc' | 'desc') => {
        const sort = sortOrder === 'asc' ? 'created_at' : '-created_at';
        updateQueryParams({ sort });
    };

    return (
        <Grid item xs={12} sm={4} md={3} lg={2}>
            <ToggleButtonGroup
                value={
                    queryString.parse(location.search).sort === 'created_at' ? 'asc' :
                    queryString.parse(location.search).sort === '-created_at' ? 'desc' : 'desc'
                }
                color="primary"
                exclusive
                onChange={(event, value) => { handleSortChange(event, value as 'asc' | 'desc') }}
                aria-label="sort order"
                size="small"
                fullWidth
            >
                <Tooltip
                    title="Show Newest First"
                    arrow
                    enterDelay={500}
                    enterNextDelay={500}
                    enterTouchDelay={500}
                    TransitionComponent={Fade}
                    TransitionProps={{ timeout: 600 }}
                    slotProps={{
                        popper: {
                            modifiers: [
                                {
                                    name: 'offset',
                                    options: {
                                        offset: [0, -18],
                                    },
                                },
                            ],
                        },
                    }}
                >
                    <ToggleButton value="desc" aria-label="descending">
                        <IconButton><ArrowDownwardIcon /></IconButton>
                    </ToggleButton>
                </Tooltip>
                <Tooltip
                    title="Show Oldest First"
                    arrow
                    enterDelay={500}
                    enterNextDelay={500}
                    enterTouchDelay={500}
                    TransitionComponent={Fade}
                    TransitionProps={{ timeout: 600 }}
                    slotProps={{
                        popper: {
                            modifiers: [
                                {
                                    name: 'offset',
                                    options: {
                                        offset: [0, -18],
                                    },
                                },
                            ],
                        },
                    }}
                >
                    <ToggleButton value="asc" aria-label="ascending">
                        <IconButton><ArrowUpwardIcon /></IconButton>
                    </ToggleButton>
                </Tooltip>
            </ToggleButtonGroup>
        </Grid>
    )
}