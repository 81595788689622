import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/X';
import InstagramIcon from '@mui/icons-material/Instagram';

import { LANDING_PAGES, SOCIALS } from 'src/config';
import { XDialog } from './base/XDialog';
import TermsConditions from './legal/TermsConditions';
import { useState } from 'react';
import SecurityPrivacy from './legal/SecurityPrivacy';

// ----------------------------------------------------------------------

function Copyright() {
    return (
        <Typography variant="body2" color="text.secondary" mt={1}>
            {'Copyright © '}
            <Link href={LANDING_PAGES.home}>ImageShield</Link>
            &nbsp;{new Date().getFullYear()}
        </Typography>
    );
}

export default function MainFooter() {

    const [tAndCOpen, setTAndCOpen] = useState<boolean>(false);
    const [securityOpen, setSecurityOpen] = useState<boolean>(false);

    return (
        <Container
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: { xs: 4, sm: 8 },
                py: { xs: 8, sm: 10 },
                textAlign: { sm: 'center', md: 'left' },
            }}
        >

            <XDialog
                open={tAndCOpen}
                onClose={() => { setTAndCOpen(false) }}
            >
                <TermsConditions setOpen={setTAndCOpen} />
            </XDialog>
            <SecurityPrivacy open={securityOpen} setOpen={setSecurityOpen} />
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    pt: { xs: 4, sm: 8 },
                    width: '100%',
                    borderTop: '1px solid',
                    borderColor: 'divider',
                }}
            >
                <div>
                    <Link
                        color="text.secondary"
                        href="#"
                        onClick={() => { setSecurityOpen(true) }}
                    >
                        Privacy Policy
                    </Link>
                    <Typography display="inline" sx={{ mx: 0.5, opacity: 0.5 }}>
                        &nbsp;•&nbsp;
                    </Typography>
                    <Link
                        color="text.secondary"
                        href="#"
                        onClick={() => { setTAndCOpen(true) }}
                    >
                        Terms and Conditions
                    </Link>
                    <Copyright />
                </div>
                <Stack
                    direction="row"
                    justifyContent="left"
                    spacing={1}
                    useFlexGap
                    sx={{
                        color: 'text.secondary',
                    }}
                >
                    <IconButton
                        color="inherit"
                        href={SOCIALS.instagram}
                        aria-label="Instagram"
                        sx={{ alignSelf: 'center' }}
                    >
                        <InstagramIcon />
                    </IconButton>
                    <IconButton
                        color="inherit"
                        href={SOCIALS.facebook}
                        aria-label="Facebook"
                        sx={{ alignSelf: 'center' }}
                    >
                        <FacebookIcon />
                    </IconButton>
                    <IconButton
                        color="inherit"
                        href={SOCIALS.x}
                        aria-label="X"
                        sx={{ alignSelf: 'center' }}
                    >
                        <TwitterIcon />
                    </IconButton>
                </Stack>
            </Box>
        </Container>
    );
}