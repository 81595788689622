/* eslint-disable react-hooks/exhaustive-deps */
import { Fade, Grid, IconButton, Tooltip } from '@mui/material';
import { DataGrid, GridCellParams, GridColDef } from '@mui/x-data-grid';
import queryString from 'query-string';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useApiCall } from 'src/hooks/useApiCall';
import { useLocation, useNavigate } from 'react-router-dom';
import Page from 'src/components/base/Page';
import { formatDate } from 'src/utils/formatDate';
import Iconify from 'src/components/base/Iconify';
import { ProfileImageType } from 'src/@types/types';
import { capWords } from 'src/utils/capWords';
import DeleteConfirmPopover from 'src/components/base/DeleteConfirmPopover';
import { copyToClipboard } from 'src/utils/copyToClipboard';
import { useSnackbar } from 'notistack';
import LoadingBackdrop from 'src/components/base/LoadingBackdrop';

// ----------------------------------------------------------------------

export default function PhotosList() {

    const location = useLocation();
    const navigate = useNavigate();
    const { apiCall } = useApiCall();
    const { enqueueSnackbar } = useSnackbar();

    const queryParams: queryString.ParsedQuery = queryString.parse(location.search);
    const queryParamsRef = useRef(queryParams);
    queryParamsRef.current = queryParams;

    const [photos, setPhotos] = useState<ProfileImageType[]>([]);
    const [pageSize, setPageSize] = useState<number>(0);
    const [totalItems, setTotalItems] = useState<number>(0);
    const [totalPages, setTotalPages] = useState<number>(0);
    const [currentPage, setCurrentPage] = useState<number>(0);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [searchTerm, setSearchTerm] = useState<string>(queryParams.name as string || '');
    const [awaitingResponse, setAwaitingResponse] = useState<boolean>(false);
    const [photoDelete, setPhotoDelete] = useState<ProfileImageType | null>(null);
    const [deleteConfirmOpen, setDeleteConfirmOpen] = useState<boolean>(false);
  
    useEffect(() => {
        // Safely update searchTerm based on the URL's query parameter
        setSearchTerm(typeof queryParams.name === 'string' ? queryParams.name : '');
    }, [queryParams.name]);

    // Helper to update query parameters in the URL
    const updateQueryParams = useCallback((updates: Record<string, string | undefined>) => {
        const queryParams = queryString.parse(location.search);
        const newParams = { ...queryParams, ...updates };
        navigate(`${location.pathname}?${queryString.stringify(newParams)}`, { replace: true });
    }, [location, navigate]);

    const fetchInfo = useCallback(() => {
        setAwaitingResponse(true);
        let url = 'a/get_all_images';
        const queryParams = queryString.parse(location.search);
        if (Object.keys(queryParams).length > 0) {
            url += `?${queryString.stringify(queryParams)}`;
        }
        apiCall(url, 'GET', null, (data) => {
            setTotalPages(data.total_pages);
            setCurrentPage(data.current_page);
            setPageSize(data.page_size);
            setTotalItems(data.total_items);
            setPhotos(data.results);
        }).then(() => {
            setAwaitingResponse(false);
        });
    }, [location.search, apiCall]);

    useEffect(() => { fetchInfo() }, [location.search]);

    const handlePageChange = (page: number) => {
        updateQueryParams({ page: (1 + page).toString() });
    };

    function updatePriority(image_id: number, priority: string) {
        setAwaitingResponse(true);
        const getFormData = () => {
            const formData = new FormData();
            formData.append('priority', priority);
        
            return formData;
        }
        apiCall(`a/update_image/${image_id}`, 'POST', getFormData(), (data) => {
            const new_image = data;
            const index = photos.findIndex((image) => image.id === new_image.id);
            const newPhotos = [...photos];
            newPhotos[index] = new_image;
            setPhotos(newPhotos);
        }).then(() => {
            setAwaitingResponse(false);
        });
    }

    const columns: GridColDef[] = [
        {
            field: 'owner__user__email',
            headerName: 'Email',
            sortable: true,
            disableColumnMenu: true,
            renderCell: (params) => params.row.owner.user.email,
            minWidth: 140,
            flex: 1,
        },
        {
            field: 'status',
            headerName: 'Status',
            sortable: true,
            disableColumnMenu: true,
            renderCell: (params) => capWords(params.row.status),
            minWidth: 100
        },
        {
            field: 'priority',
            headerName: 'Priority',
            sortable: true,
            disableColumnMenu: true,
            renderCell: (params) => capWords(params.row.priority),
            editable: true,
            type: 'singleSelect',
            valueOptions: ['Low', 'Medium', 'High'],
            minWidth: 140
        },
        {
            field: 'last_infringed',
            headerName: 'Last Abused',
            sortable: true,
            disableColumnMenu: true,
            renderCell: (params) => formatDate(params.row.last_infringed || '-'),
            minWidth: 100
        },
        {
            field: 'created_at',
            headerName: 'Uploaded At',
            sortable: true,
            disableColumnMenu: true,
            renderCell: (params) => formatDate(params.row.created_at),
            minWidth: 100
        },
        {
            field: '',
            headerName: 'Actions',
            headerAlign: 'right',
            align: 'right',
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params) => (
                <>
                    <Tooltip
                        title="Copy ID to Clipboard"
                        arrow
                        enterDelay={500}
                        enterNextDelay={500}
                        enterTouchDelay={500}
                        TransitionComponent={Fade}
                        TransitionProps={{ timeout: 600 }}
                    >
                        <IconButton
                            size={'small'}
                            color={'primary'}
                            onClick={() => {
                                copyToClipboard(params.row.admin_ref_id);
                                enqueueSnackbar('ID copied to clipboard', { variant: 'success', autoHideDuration: 750 });
                            }}
                        >
                            <Iconify icon={'eva:copy-outline'} />
                        </IconButton>
                    </Tooltip>
                    <Tooltip
                        title="Delete"
                        arrow
                        enterDelay={500}
                        enterNextDelay={500}
                        enterTouchDelay={500}
                        TransitionComponent={Fade}
                        TransitionProps={{ timeout: 600 }}
                    >
                        <IconButton size={'small'} color={'error'} onClick={() => { setPhotoDelete(params.row); setDeleteConfirmOpen(true) }}>
                            <Iconify icon={'eva:trash-2-outline'} />
                        </IconButton>
                    </Tooltip>
                </>
            ),
            minWidth: 80
        }
    ];

    function handleProcessRowUpdate(newRow: ProfileImageType, oldRow: ProfileImageType) {
        if (newRow.priority === undefined) {
            newRow.priority = oldRow.priority;
        } else if (newRow.priority !== oldRow.priority) {
            updatePriority(newRow.id, newRow.priority);
        }
        return newRow;
    }

    return (
        <Page title="Photos">
            <DeleteConfirmPopover
                url={`remove_image/${photoDelete?.id}`}
                open={deleteConfirmOpen}
                setOpen={setDeleteConfirmOpen}
            />
            {awaitingResponse && <LoadingBackdrop />}
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <DataGrid
                        filterMode={'server'}
                        paginationMode={'server'}
                        editMode={'row'}
                        pageSizeOptions={[pageSize]}
                        paginationMeta={{
                            hasNextPage: currentPage < totalPages,
                        }}
                        onPaginationModelChange={(model) => handlePageChange(model.page)}
                        rowCount={totalItems}
                        estimatedRowCount={totalItems}
                        paginationModel={{
                            page: currentPage - 1,
                            pageSize: pageSize,
                        }}
                        rows={photos}
                        // loading={awaitingResponse}
                        columns={columns}
                        rowSelection={false}
                        getCellClassName={(params: GridCellParams<any, any, number>) => {
                            if (params.field === 'city' || params.value == null) {
                                return '';
                            }
                            return params.value >= 15 ? 'hot' : 'cold';
                        }}
                        processRowUpdate={handleProcessRowUpdate}
                        onSortModelChange={(model) => {
                            if (model.length > 0) {
                                const sort_direction = model[0].sort === 'asc' ? '' : '-';
                                updateQueryParams({ sort: sort_direction + model[0].field });
                            } else {
                                updateQueryParams({ sort: undefined });
                            }
                        }}
                    />
                </Grid>
            </Grid>
        </Page>
    )
}