import { Theme } from '@mui/material';

// ----------------------------------------------------------------------

export default function Drawer(theme: Theme) {

  const drawerWidth = 240;

  return {
    MuiDrawer: {
      styleOverrides: {
        paper: {
          whiteSpace: 'nowrap',
          width: drawerWidth,
          transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
          }),
          boxSizing: 'border-box',
          '&.MuiDrawer-close': {
            overflowX: 'hidden',
            transition: theme.transitions.create('width', {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.leavingScreen,
            }),
            width: theme.spacing(7),
            [theme.breakpoints.up('sm')]: {
              width: theme.spacing(9),
            },
          },
        },
      },
    },
  };
}