import { Button, Card, CardActions, CardContent, Grid, Typography, Stack } from "@mui/material"

import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';

// ----------------------------------------------------------------------

type AddNewImgCardProps = {
    setUploadOpen: (value: boolean) => void;
}

export default function AddNewImgCard({ setUploadOpen }: AddNewImgCardProps) {
    return (
        <Grid item xs={6} md={4} lg={3}>
            <Card
                sx={{
                    width: '100%',
                    position: 'relative',
                    '&:hover': {
                        filter: 'brightness(0.9)',
                        cursor: 'pointer',
                    }
                }}
                onClick={() => { setUploadOpen(true) }}
            >
                <CardContent
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: 200
                    }}
                >
                    <Stack alignItems="center" justifyContent="space-between" spacing={1}>
                        <AddAPhotoIcon
                            sx={{ fontSize: 40 }}
                            color='primary'
                        />
                        <Typography variant="button" color="text.disabled">Upload Photos</Typography>
                    </Stack>
                </CardContent>
                <CardActions>
                    <Button size="small" sx={{ visibility: 'hidden' }}>Hidden Button</Button>
                </CardActions>
            </Card>
        </Grid>
    )
}