// @mui
import { Container, Stack } from '@mui/material';
// components
import Page from "src/components/base/Page";
// sections
import ResetForm from 'src/auth/pass-reset/ResetForm';
import { MAIN_LOGO } from 'src/config';
import { useNavigate } from 'react-router-dom';
import { PATH_GUEST } from 'src/routes/paths';

// ----------------------------------------------------------------------

export default function ResetPassword() {

    const navigate = useNavigate();

    return (
        <Page title="ResetPassword">
            <Container maxWidth="sm">
                <Stack sx={{ justifyContent: 'center', alignItems: 'center', my: 2 }} direction="row" spacing={1}>
                    <img
                        src={MAIN_LOGO}
                        alt="IS Logo"
                        onClick={() => { navigate(PATH_GUEST.login) }}
                        style={{ cursor: 'pointer' }}
                    />
                </Stack>

                <ResetForm />

            </Container>
        </Page>
    );
}