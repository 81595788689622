/* eslint-disable react-hooks/exhaustive-deps */
import { Fade, Grid, IconButton, Tooltip, Stack, Button, OutlinedInput, Typography } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import queryString from 'query-string';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useApiCall } from 'src/hooks/useApiCall';
import { useLocation, useNavigate } from 'react-router-dom';
import { AuthProfile } from 'src/@types/auth';
import Page from 'src/components/base/Page';
import { formatDate } from 'src/utils/formatDate';
import Iconify from 'src/components/base/Iconify';
import DeleteConfirmPopover from 'src/components/base/DeleteConfirmPopover';
import AddressForm from 'src/components/signup/AddressForm';
import { XDialog } from 'src/components/base/XDialog';
import LoadingBackdrop from 'src/components/base/LoadingBackdrop';

// ----------------------------------------------------------------------

export default function AdminMgmt() {

    const location = useLocation();
    const navigate = useNavigate();
    const { apiCall } = useApiCall();

    const queryParams: queryString.ParsedQuery = queryString.parse(location.search);
    const queryParamsRef = useRef(queryParams);
    queryParamsRef.current = queryParams;

    const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
    const [admins, setAdmins] = useState<AuthProfile[]>([]);
    const [pageSize, setPageSize] = useState<number>(0);
    const [totalItems, setTotalItems] = useState<number>(0);
    const [totalPages, setTotalPages] = useState<number>(0);
    const [currentPage, setCurrentPage] = useState<number>(0);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [searchTerm, setSearchTerm] = useState<string>(queryParams.name as string || '');
    const [awaitingResponse, setAwaitingResponse] = useState<boolean>(false);
    const [deleteAdmin, setDeleteAdmin] = useState<AuthProfile | null>(null);
    const [passResetOpen, setPassResetOpen] = useState<boolean>(false);
    const [passResetAdmin, setPassResetAdmin] = useState<AuthProfile | null>(null);
  
    useEffect(() => {
        // Safely update searchTerm based on the URL's query parameter
        setSearchTerm(typeof queryParams.name === 'string' ? queryParams.name : '');
    }, [queryParams.name]);

    // Helper to update query parameters in the URL
    const updateQueryParams = useCallback((updates: Record<string, string | undefined>) => {
        const queryParams = queryString.parse(location.search);
        const newParams = { ...queryParams, ...updates };
        navigate(`${location.pathname}?${queryString.stringify(newParams)}`, { replace: true });
    }, [location, navigate]);

    const fetchInfo = useCallback(() => {
        setAwaitingResponse(true);
        let url = 'a/get_admins';
        const queryParams = queryString.parse(location.search);
        if (Object.keys(queryParams).length > 0) {
            url += `?${queryString.stringify(queryParams)}`;
        }
        apiCall(url, 'GET', null, (data) => {
            setTotalPages(data.total_pages);
            setCurrentPage(data.current_page);
            setPageSize(data.page_size);
            setTotalItems(data.total_items);
            setAdmins(data.results);
        }).then(() => {
            setAwaitingResponse(false);
        });
    }, [location.search, apiCall]);

    useEffect(() => { fetchInfo() }, [location.search]);

    const handlePageChange = (page: number) => {
        updateQueryParams({ page: (1 + page).toString() });
    };

    const columns: GridColDef[] = [
        {
            field: 'user__email',
            headerName: 'Email',
            sortable: true,
            disableColumnMenu: true,
            renderCell: (params) => params.row.user.email,
            minWidth: 140,
            flex: 1,
        },
        {
            field: 'user__first_name',
            headerName: 'First Name',
            sortable: true,
            disableColumnMenu: true,
            renderCell: (params) => params.row.user.first_name,
            minWidth: 130
        },
        {
            field: 'user__last_name',
            headerName: 'Last Name',
            sortable: true,
            disableColumnMenu: true,
            renderCell: (params) => params.row.user.last_name,
            minWidth: 130
        },
        {
            field: 'created_at',
            headerName: 'Created At',
            sortable: true,
            disableColumnMenu: true,
            renderCell: (params) => formatDate(params.row.created_at),
            minWidth: 120
        },
        {
            field: '',
            headerName: 'Actions',
            headerAlign: 'right',
            align: 'right',
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params) => (
                <>
                    <Tooltip
                        title="Reset Password"
                        arrow
                        enterDelay={500}
                        enterNextDelay={500}
                        enterTouchDelay={500}
                        TransitionComponent={Fade}
                        TransitionProps={{ timeout: 600 }}
                    >
                        <IconButton size={'small'} color={'primary'} onClick={() => { setPassResetAdmin(params.row); setPassResetOpen(true) }}>
                            <Iconify icon={'mdi:password-reset'} />
                        </IconButton>
                    </Tooltip>
                    <Tooltip
                        title="Delete"
                        arrow
                        enterDelay={500}
                        enterNextDelay={500}
                        enterTouchDelay={500}
                        TransitionComponent={Fade}
                        TransitionProps={{ timeout: 600 }}
                    >
                        <IconButton size={'small'} color={'error'} onClick={() => { setDeleteAdmin(params.row); setDeleteConfirmOpen(true) }}>
                            <Iconify icon={'eva:trash-2-outline'} />
                        </IconButton>
                    </Tooltip>
                </>
            ),
            minWidth: 80
        }
    ];

    return (
        <Page title="Admins">
            <CreateAdmin />
            {awaitingResponse && <LoadingBackdrop />}
            <DeleteConfirmPopover
                url={`a/delete_admin/${deleteAdmin?.id}`}
                open={deleteConfirmOpen}
                setOpen={setDeleteConfirmOpen}
            />
            <ResetPassword
                open={passResetOpen && passResetAdmin !== null}
                setOpen={setPassResetOpen}
                admin={passResetAdmin}
            />
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <DataGrid
                        filterMode={'server'}
                        paginationMode={'server'}
                        pageSizeOptions={[pageSize]}
                        paginationMeta={{
                            hasNextPage: currentPage < totalPages,
                        }}
                        onPaginationModelChange={(model) => handlePageChange(model.page)}
                        rowCount={totalItems}
                        estimatedRowCount={totalItems}
                        paginationModel={{
                            page: currentPage - 1,
                            pageSize: pageSize,
                        }}
                        rows={admins}
                        // loading={awaitingResponse}
                        columns={columns}
                        rowSelection={false}
                        onSortModelChange={(model) => {
                            if (model.length > 0) {
                                const sort_direction = model[0].sort === 'asc' ? '' : '-';
                                updateQueryParams({ sort: sort_direction + model[0].field });
                            } else {
                                updateQueryParams({ sort: undefined });
                            }
                        }}
                    />
                </Grid>
            </Grid>
        </Page>
    )
}

// ----------------------------------------------------------------------

function CreateAdmin() {

    const { apiCall } = useApiCall();
    const [open, setOpen] = useState<boolean>(false);
    const [awaitingResponse, setAwaitingResponse] = useState<boolean>(false);
    const [password, setPassword] = useState<string>('');
    const [confirmPassword, setConfirmPassword] = useState<string>('');
    const [buttonDisabled, setButtonDisabled] = useState<boolean>(true);
    const [newAdmin, setNewAdmin] = useState<AuthProfile>({
        id: 0,
        user: {
            id: 0,
            first_name: '',
            last_name: '',
            email: '',
            is_staff: true,
            is_active: true
        },
        balance: 0,
        account_status: 'active',
        email_opt_out: false,
    });

    function createAdmin() {
        setAwaitingResponse(true);
        const getFormData = () => {
            const formData = new FormData();
            formData.append('first_name', newAdmin?.user.first_name || '');
            formData.append('last_name', newAdmin?.user.last_name || '');
            formData.append('email', newAdmin?.user.email || '');
            formData.append('password', password);

            return formData;
        };
        apiCall('a/create_admin/', 'POST', getFormData(), (data) => {
            console.log(data);
            window.location.reload();
        });
    };

    return (
        <>
            <Stack sx={{ alignItems: 'center', width: '100%' }}>
                <Button
                    onClick={() => { setOpen(true) }}
                    variant='contained'
                    sx={{ mb: 3 }}
                >
                    Create Admin
                </Button>
            </Stack>
            <XDialog
                open={open}
                onClose={() => { setOpen(false) }}
            >
                {awaitingResponse && <LoadingBackdrop />}
                <Stack spacing={3} sx={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                    <AddressForm
                        isAdmin
                        firstName={newAdmin?.user.first_name || ''}
                        setFirstName={
                            (value: string) => {
                                if (newAdmin) {
                                    newAdmin.user.first_name = value;
                                    setNewAdmin(newAdmin);
                                }
                            }
                        }
                        lastName={newAdmin?.user.last_name || ''}
                        setLastName={
                            (value: string) => {
                                if (newAdmin) {
                                    newAdmin.user.last_name = value;
                                    setNewAdmin(newAdmin);
                                }
                            }
                        }
                        email={newAdmin?.user.email || ''}
                        setEmail={
                            (value: string) => {
                                if (newAdmin) {
                                    newAdmin.user.email = value;
                                    setNewAdmin(newAdmin);
                                }
                            }
                        }
                        password={password}
                        setPassword={setPassword}
                        confirmPassword={confirmPassword}
                        setConfirmPassword={setConfirmPassword}
                        setButtonDisabled={setButtonDisabled}
                    />
                    <Stack direction="row" spacing={2}>
                        <Button
                            onClick={() => { setOpen(false) }}
                            variant='outlined'
                            fullWidth
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={createAdmin}
                            variant='contained'
                            fullWidth
                            disabled={buttonDisabled}
                        >
                            Create
                        </Button>
                    </Stack>
                </Stack>
            </XDialog>
        </>
    )
}

// ----------------------------------------------------------------------

type ResetPasswordProps = {
    open: boolean;
    setOpen: (value: boolean) => void;
    admin: AuthProfile;
};

function ResetPassword({ open, setOpen, admin }: ResetPasswordProps) {

    const { apiCall } = useApiCall();

    const [awaitingResponse, setAwaitingResponse] = useState<boolean>(false);
    const [passwordIsReset, setPasswordIsReset] = useState<boolean>(false);
    const [newPassword, setPassword] = useState<string>('');
    const [confirmPassword, setConfirmPassword] = useState<string>('');

    function resetPassword() {
        setAwaitingResponse(true);
        const getFormData = () => {
            const formData = new FormData();
            formData.append('password', newPassword);
            return formData;
        }

        apiCall(`a/reset_password/${admin?.user.id}`, 'POST', getFormData(), (data) => {
            console.log(data);
            setPasswordIsReset(true);
            setAwaitingResponse(false);
        });
    }

    function closeDialog() {
        setOpen(false);
        setPasswordIsReset(false);
        setPassword('');
        setConfirmPassword('');
    };

    return (
        <XDialog
            open={open}
            onClose={closeDialog}
        >
            <Stack spacing={3}>
                {awaitingResponse && <LoadingBackdrop />}
                {!passwordIsReset &&
                    <Stack spacing={3} justifyContent={'center'}>
                        <OutlinedInput
                            id="new-password"
                            name="password"
                            type="password"
                            placeholder={'New Password'}
                            autoComplete="password"
                            required={true}
                            onChange={(e) => { setPassword(e.target.value) }}
                        />
                        <OutlinedInput
                            id="confirm-password"
                            name="confirm-password"
                            type="password"
                            placeholder={'Confirm Password'}
                            autoComplete="password"
                            required={true}
                            onChange={(e) => { setConfirmPassword(e.target.value) }}
                        />
                        <Stack direction="row" spacing={2}>
                            <Button
                                onClick={closeDialog}
                                variant='outlined'
                                fullWidth
                            >
                                Cancel
                            </Button>
                            <Button
                                onClick={resetPassword}
                                variant='contained'
                                fullWidth
                                disabled={
                                    newPassword.length < 1 ||
                                    newPassword !== confirmPassword
                                }
                            >
                                Reset
                            </Button>
                        </Stack>
                    </Stack>
                }
                {passwordIsReset &&
                    <Stack spacing={3} justifyContent={'center'} alignItems={'center'}>
                        <Typography variant={'body1'}>
                            Password reset successfully!
                        </Typography>
                        <Button
                            onClick={closeDialog}
                            variant='contained'
                            fullWidth
                        >
                            Close
                        </Button>
                    </Stack>
                }
            </Stack>
        </XDialog>
    )
}