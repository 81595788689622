import { Link as RouterLink } from 'react-router-dom';
import { Box, BoxProps } from "@mui/material";
import { MAIN_LOGO, PATH_AFTER_LOGIN } from 'src/config';

// ----------------------------------------------------------------------

interface Props extends BoxProps {
    smallVersion?: boolean;
    disabledLink?: boolean;
}

export default function Logo({ smallVersion = false, disabledLink = false, sx }: Props) {

    const logo = (
        <Box sx={{ width: '100%', height: '100%', ...sx }}>
            { smallVersion ?
                <img src="https://is-assets-v2.s3.amazonaws.com/assets/is_icon_xl.png" alt="IS Logo Small" /> :
                <img src={MAIN_LOGO} alt="IS Logo" />
            }
        </Box>
    );

    if (disabledLink) {
        return <>{logo}</>;
    }

    return <RouterLink to={PATH_AFTER_LOGIN}>{logo}</RouterLink>;
}