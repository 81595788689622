import { Accordion, AccordionDetails, AccordionSummary, Box, Button, CircularProgress, Fade, Grid, IconButton, Link, Stack, SxProps, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography, alpha, useTheme } from "@mui/material";
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useEffect, useState } from "react";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { BaseReportType, ViolatorType } from "src/@types/types";
import Page from "src/components/base/Page";
import { formatDate } from "src/utils/formatDate";
import LoadingBackdrop from "src/components/base/LoadingBackdrop";
import { useApiCall } from "src/hooks/useApiCall";
import { copyToClipboard } from "src/utils/copyToClipboard";
import Iconify from "src/components/base/Iconify";
import { XDialog } from "src/components/base/XDialog";
import { TRANSITIONS } from "src/config";
import { useSnackbar } from "notistack";

// ----------------------------------------------------------------------

type ReportSite = {
    name: string,
    sudo?: string,
    url: string,
}

const reportSites: ReportSite[] = [
    { name: "Blogger", url: "https://support.google.com/legal/troubleshooter/1114905?hl=en" },
    { name: "Facebook", url: "https://www.facebook.com/help/contact/634636770043106" },
    { name: "Flickr", url: "https://www.flickr.com/abuse/dmca/" },
    { name: "Instagram", url: "https://help.instagram.com/contact/552695131608132" },
    { name: "Imgur", url: "https://imgur.com/removalrequest" },
    { name: "LinkedIn", url: "https://www.linkedin.com/help/linkedin/ask/TS-NCI" },
    { name: "Medium", url: "https://help.medium.com/hc/en-us/requests/new?ticket_form_id=165717#/201931128/200737695/" },
    { name: "Onlyfans", url: "https://onlyfans.com/dmca" },
    { name: "Pinterest", url: "https://www.pinterest.com/about/copyright/dmca-pin/" },
    { name: "Pornhub", url: "https://www.pornhub.com/content-removal" },
    { name: "Quora", url: "https://help.quora.com/hc/en-us/requests/new?ticket_form_id=360000010543" },
    { name: "Reddit", url: "https://support.reddithelp.com/hc/en-us/requests/new?ticket_form_id=16510899084308" },
    { name: "Snapchat", url: "https://help.snapchat.com/hc/en-us/requests/new" },
    { name: "TikTok", url: "https://www.tiktok.com/legal/report/Copyright" },
    { name: "Tumblr", url: "https://www.tumblr.com/dmca" },
    { name: "WordPress", url: "https://automattic.com/copyright-policy/" },
    { name: "X", sudo: "Twitter", url: "https://help.twitter.com/forms/dmca" },
    { name: "YouTube", url: "https://www.youtube.com/copyright_complaint_form" },
];

// ----------------------------------------------------------------------

export default function Reports() {

    const { apiCall } = useApiCall();

    const [reports, setReports] = useState<BaseReportType[]>([]);
    const [awaitingResponse, setAwaitingResponse] = useState<boolean>(true);
    const [howToReportImagesOpen, setHowToReportImagesOpen] = useState<boolean>(false);
    
    const getReports = () => {
        apiCall("get_reports", "GET", null, (data) => {
            setReports(data);
            setAwaitingResponse(false);
        });
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => { getReports() }, []);

    return (
        <Page title="Reports">
            {awaitingResponse && <LoadingBackdrop />}

            <Grid container spacing={3} sx={{ display: 'flex', justifyContent: 'center' }}>
                {reports?.length === 0 && !awaitingResponse &&
                    <Grid item><Typography>No Reports Found</Typography></Grid>
                }
                {reports?.length > 0 && !awaitingResponse &&
                    <Grid item xs={12}>
                        <HowToReportImages
                            open={howToReportImagesOpen}
                            setOpen={setHowToReportImagesOpen}
                        />
                    </Grid>
                }
                {reports.map((report, index) => (
                    <ReportSummary
                        key={`report_${report.id}`}
                        isMostRecent={index === 0}
                        report={report}
                        setHowToReportImagesOpen={setHowToReportImagesOpen}
                    />
                ))}
            </Grid>
        </Page>
    )
}

// ----------------------------------------------------------------------

type ReportSummaryProps = {
    isMostRecent: boolean,
    report: BaseReportType,
    setHowToReportImagesOpen: (open: boolean) => void
}

function ReportSummary({ isMostRecent, report, setHowToReportImagesOpen }: ReportSummaryProps) {

    const { apiCall } = useApiCall();
    const [open, setOpen] = useState<boolean>((isMostRecent && report.infringed_images.length > 0) ? true : false);

    const disabled = !report.infringed_images.length;

    const theme = useTheme();
    const open_background_color = disabled ? 
        alpha(theme.palette.grey[500], theme.palette.action.disabledOpacity) :
        alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity);

    const handleClick = () => {
        if (disabled) { return };
        setOpen(!open);
        if (!report.completed_at || report.owner_viewed) { return };
        apiCall(`mark_report_as_viewed/${report.id}`, "POST", null, () => {});
    };

    const typography_sx: SxProps = {
        transition: TRANSITIONS.text,
        ...(open && { color: theme.palette.primary.main }),
    }

    return (
        <Grid item xs={12}>
            <Accordion
                disableGutters={false}
                expanded={open && report.infringed_images.length > 0}
                sx={{
                    ...(open && { backgroundColor: 'transparent' }),
                    ...(disabled && {
                        backgroundColor: alpha(theme.palette.grey[500], theme.palette.action.disabledOpacity),
                        pointerEvents: 'none'
                    }),
                }}
                slotProps={{ transition: { unmountOnExit: true } }}
            >
                <AccordionSummary
                    onClick={handleClick}
                    expandIcon={<ExpandMoreIcon />}
                    sx={{
                        borderRadius: '8px',
                        transition: TRANSITIONS.accordion,
                        color: theme.palette.text.secondary,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        ...(open && { backgroundColor: open_background_color }),
                    }}
                >
                    <Stack direction={'row'} alignItems={'center'} spacing={1}>
                        {!report.completed_at &&
                            <Iconify icon={'eva:clock-outline'} sx={{ ...typography_sx }} />
                        }
                        {report.infringed_images.length === 0 &&
                            <Iconify icon={'eva:checkmark-circle-2-outline'} sx={{ ...typography_sx, ...(!open && { color: theme.palette.success.main }) }} />
                        }
                        {report.infringed_images.length > 0 && report.owner_viewed &&
                            <Iconify icon={'eva:eye-outline'} sx={{ ...typography_sx, ...(!open && { color: theme.palette.primary.light }) }} />
                        }
                        {report.infringed_images.length > 0 && !report.owner_viewed &&
                            <Iconify icon={'eva:alert-circle-outline'} sx={{ ...typography_sx, ...(!open && { color: theme.palette.warning.main }) }} />
                        }
                        <Typography sx={{ ...typography_sx }}>
                            {report.completed_at ? formatDate(report.completed_at) : '-'} ({report.infringed_images.length} Potentially Abused Photos)
                        </Typography>
                    </Stack>
                </AccordionSummary>
                <AccordionDetails sx={{ pt: 3, backgroundColor: 'transparent' }}>
                    <ReportTable
                        open={open}
                        report={report}
                        setHowToReportImagesOpen={setHowToReportImagesOpen}
                    />
                </AccordionDetails>
            </Accordion>
        </Grid>
    )
}

// ----------------------------------------------------------------------

type ReportTableProps = {
    open: boolean,
    report: BaseReportType,
    setHowToReportImagesOpen: (open: boolean) => void
}

function ReportTable({ open, report, setHowToReportImagesOpen }: ReportTableProps) {

    const { apiCall } = useApiCall();
    const { enqueueSnackbar } = useSnackbar();

    const [awaitingResponse, setAwaitingResponse] = useState<boolean>(true);
    const [violators, setViolators] = useState<ViolatorType[]>([]);

    const [queryParams, setQueryParams] = useState<string>('');

    const [pageSize, setPageSize] = useState<number>(0);
    const [totalItems, setTotalItems] = useState<number>(0);
    const [totalPages, setTotalPages] = useState<number>(0);
    const [currentPage, setCurrentPage] = useState<number>(0);

    const getViolators = (page_number: number) => {
        let url = `get_violators_of_report/${report.id}?page=${page_number}`;
        if (queryParams) {
            url += `&${queryParams}`;
        } else {
            setAwaitingResponse(true);
        }
        apiCall(url, "GET", null, (data) => {
            setTotalPages(data.total_pages);
            setCurrentPage(data.current_page);
            setPageSize(data.page_size);
            setTotalItems(data.total_items);
            setViolators(data.results);
        }).then(() => {
            setAwaitingResponse(false);
        });
    }

    useEffect(() => {
        if (!open) { return };
        getViolators(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    useEffect(() => {
        if (currentPage < 1) { return };
        getViolators(currentPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [queryParams]);

    const handlePageChange = (current_page_number: number) => {
        getViolators(current_page_number + 1);
    };

    const columns: GridColDef[] = [
        {
            field: 'url',
            headerName: 'Site URL',
            sortable: true,
            disableColumnMenu: true,
            renderCell: (params) => (
                <Link href={params.row.url} target="_blank" rel="noopener noreferrer">
                    {params.row.url}
                </Link>
            ),
            flex: 1,
            minWidth: 200
        },
        {
            field: '',
            headerName: 'Actions',
            headerAlign: 'right',
            align: 'right',
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params) => {
                const site = reportSites.find((site) => {
                    const url = new URL(params.row.url.toLowerCase());
                    const domain = url.hostname.replace('www.', '');
                    if (site.sudo) {
                        return domain.includes(site.sudo.toLowerCase()) || domain.includes(site.name.toLowerCase());
                    }
                    return domain.includes(site.name.toLowerCase());
                });
                return (
                    <>
                        <Tooltip title="Copy URL to Clipboard" arrow>
                            <IconButton
                                size={'small'}
                                color={'primary'}
                                onClick={() => {
                                    copyToClipboard(params.row.url);
                                    enqueueSnackbar('URL copied to clipboard', { variant: 'success', autoHideDuration: 750 });
                                }}
                            >
                                <Iconify icon={'eva:copy-outline'} />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={site ? `Report Image to ${site.name}` : 'View Reporting Links'} arrow>
                            <IconButton
                                size={'small'}
                                color={'primary'}
                                onClick={() => {
                                    if (site) {
                                        window.open(site.url, '_blank', 'noopener,noreferrer,noreferrer');
                                    } else {
                                        setHowToReportImagesOpen(true);
                                    }
                                }}
                            >
                                <Iconify icon={site ? 'eva:external-link-outline' : 'eva:link-2-outline'} />
                            </IconButton>
                        </Tooltip>
                    </>
                )
            },
            minWidth: 80
        },
        {
            field: 'false_positive_eligible',
            headerName: '100% False Positive',
            sortable: true,
            disableColumnMenu: true,
            renderCell: (params) => params.row.false_positive_eligible ? 'Yes' : 'No',
            minWidth: 20
        },
        {
            field: 'image__admin_ref_id',
            headerName: 'Ref ID',
            sortable: true,
            disableColumnMenu: true,
            renderCell: (params) => {
                const image = report.infringed_images.find((image) => image.admin_ref_id === params.row.image_ref_id);
                if (!image) { return params.row.image_ref_id };
                return (
                    <Link href={image?.img_url} target="_blank" rel="noopener noreferrer">
                        {params.row.image_ref_id}
                    </Link>
                );
            },
            minWidth: 120
        },
    ];

    return (
        <>
            {awaitingResponse ?
                <Stack justifyContent={'center'} alignItems={'center'} spacing={2}>
                    <Box sx={{ justifyContent: 'center', alignItems: 'center' }}>
                        <CircularProgress />
                    </Box>
                </Stack> :
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <DataGrid
                            filterMode={'server'}
                            paginationMode={'server'}
                            pageSizeOptions={[pageSize]}
                            paginationMeta={{
                                hasNextPage: currentPage < totalPages,
                            }}
                            onPaginationModelChange={(model) => handlePageChange(model.page)}
                            rowCount={totalItems}
                            estimatedRowCount={totalItems}
                            paginationModel={{
                                page: currentPage - 1,
                                pageSize: pageSize,
                            }}
                            rows={violators}
                            columns={columns}
                            rowSelection={false}
                            onSortModelChange={(model) => {
                                if (model.length > 0) {
                                    const sort_direction = model[0].sort === 'asc' ? '' : '-';
                                    setQueryParams(`sort=${sort_direction + model[0].field}`);
                                } else {
                                    setQueryParams('');
                                }
                            }}
                        />
                    </Grid>
                </Grid>
            }
        </>
    )
}

// ----------------------------------------------------------------------

type HowToReportImagesProps = {
    open: boolean,
    setOpen: (open: boolean) => void
}

function HowToReportImages({ open, setOpen }: HowToReportImagesProps) {

    const theme = useTheme();

    return (
        <>
            <Button onClick={() => setOpen(true)}>How to Report Images</Button>
            <XDialog
                open={open}
                onClose={() => { setOpen(false) }}
            >
                <Stack spacing={2}>
                    <Typography paragraph textAlign={'center'}>
                        If you find your images on any of the following sites, you can report them directly to the platform using the links below.
                    </Typography>
                    <Typography paragraph textAlign={'center'}>
                        This list will be updated regularly as ImageShield analyzes the abuses of users' photos and faces.
                    </Typography>
                    <TableContainer sx={{ width: '100%' }}>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Site</TableCell>
                                    <TableCell align="right">Link</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody sx={{ '& > :nth-of-type(2n+2)': { bgcolor: alpha(theme.palette.primary.main, 0.05) } }}>
                            {reportSites?.map((site) => (
                                <TableRow key={site.name}>
                                    <TableCell>{site.name}{site.sudo && ` / ${site.sudo}`}</TableCell>
                                    <TableCell align="right">
                                        <Tooltip
                                            title={`Report Images on ${site.name}`}
                                            arrow
                                            enterDelay={500}
                                            enterNextDelay={500}
                                            enterTouchDelay={500}
                                            TransitionComponent={Fade}
                                            TransitionProps={{ timeout: 600 }}
                                        >
                                            <IconButton
                                                size={'small'}
                                                color={'primary'}
                                                onClick={() => window.open(site.url, '_blank')}
                                            >
                                                <Iconify icon={'eva:external-link-outline'} />
                                            </IconButton>
                                        </Tooltip>
                                    </TableCell>
                                </TableRow>
                            ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Stack>
            </XDialog>
        </>
    )
}