import { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import { PATH_AFTER_LOGIN } from 'src/config';
import useAuth from 'src/hooks/useAuth';

// ----------------------------------------------------------------------

type AdminGuardProps = {
  children: ReactNode;
};

export default function AdminGuard({ children }: AdminGuardProps) {
  const { isAdmin } = useAuth();

  if (!isAdmin) {
    return <Navigate to={PATH_AFTER_LOGIN} />;
  }

  return <>{children}</>;
}
