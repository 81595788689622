import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';
import OutlinedInput from '@mui/material/OutlinedInput';
import { styled } from '@mui/system';
import { useEffect, useState } from 'react';
import dayjs from "dayjs";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Alert, Box, FormControl, IconButton, InputLabel, MenuItem, Select, Stack, Switch, SxProps } from '@mui/material';
import { ACCOUNT_STATUS_CHOICES, ACCOUNT_STATUS_CHOICES_TYPE } from 'src/@types/choices';
import { capWords } from 'src/utils/capWords';
import { XDialog } from '../base/XDialog';
import TermsConditions from '../legal/TermsConditions';
import Iconify from '../base/Iconify';

const FormGrid = styled(Grid)(() => ({
    display: 'flex',
    flexDirection: 'column',
}));

type AddressFormProps = {
    isAdmin?: boolean;
    isEdit?: boolean;
    firstName: string;
    setFirstName: (firstName: string) => void;
    lastName: string;
    setLastName: (lastName: string) => void;
    email: string;
    setEmail: (email: string) => void;
    confirmEmail?: string;
    setConfirmEmail?: (confirmEmail: string) => void;
    birthday?: string;
    setBirthday?: (birthday: string) => void;
    password: string;
    setPassword: (password: string) => void;
    oldPassword?: string;
    setOldPassword?: (oldPassword: string) => void;
    confirmPassword?: string;
    setConfirmPassword?: (confirmPassword: string) => void;
    accountStatus?: ACCOUNT_STATUS_CHOICES_TYPE;
    setAccountStatus?: (accountStatus: ACCOUNT_STATUS_CHOICES_TYPE) => void;
    setButtonDisabled: (validContinue: boolean) => void;
    sx?: SxProps;
}

export default function AddressForm({
    isEdit, isAdmin, firstName, setFirstName, lastName, setLastName,
    email, setEmail, confirmEmail, setConfirmEmail, birthday, setBirthday,
    password, setPassword, oldPassword, setOldPassword, confirmPassword, setConfirmPassword,
    setButtonDisabled, accountStatus, setAccountStatus, sx
}: AddressFormProps) {

    const [passwordError, setPasswordError] = useState<string | null>(null);
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [showConfirm, setShowConfirm] = useState<boolean>(false);

    const [resetPassword, setResetPassword] = useState<boolean>(false);
    const [newConfirmPassword, setNewConfirmPassword] = useState<string>('');
    const [termsAndConditions, setTermsAndConditions] = useState<boolean>(false);
    const [tAndCOpen, setTAndCOpen] = useState<boolean>(false);

    const handleCheckboxChange = (event: any) => {
        setTermsAndConditions(event.target.checked);
    };

    const handleIconClick = () => {
        setTAndCOpen(!tAndCOpen);
    };

    function checkPasswordsMatch(pass: string, confirmPass: string | undefined) {
        if (confirmPass === '' || confirmPass === undefined) {
            return 'Please confirm your password';
        }
        if (pass.length < 8) {
            return 'Password must be at least 8 characters';
        }
        if (pass !== confirmPass) {
            return 'Passwords do not match';
        }
        return null;
    }

    useEffect(() => {
        let error = null;
    
        if (isAdmin && !isEdit) {
            // Admin New User
            error = checkPasswordsMatch(password, confirmPassword);
            setPasswordError(error);
            setButtonDisabled(!error && firstName && lastName && email && password && confirmPassword ? false : true);
    
        } else if (isAdmin && isEdit) {
            // Admin Edit User
            if (!firstName || !lastName || !email || !accountStatus) {
                setButtonDisabled(true);
                return;
            }
            if (resetPassword) {
                error = checkPasswordsMatch(password, confirmPassword);
                setPasswordError(error);
                setButtonDisabled(!error && password && confirmPassword ? false : true);
            } else {
                setButtonDisabled(false);
            }
        } else if (!isAdmin && !isEdit) {
            // New User Sign Up
            error = checkPasswordsMatch(password, newConfirmPassword);
            setPasswordError(error);
            setButtonDisabled(!error && firstName && lastName && email && password && newConfirmPassword && termsAndConditions && email === confirmEmail ? false : true);
    
        } else {
            // User Edit Profile
            error = checkPasswordsMatch(password, confirmPassword);
            setPasswordError(error);
            setButtonDisabled(!error && ((firstName && lastName && email && password && confirmPassword) || (firstName && lastName && email && password && confirmPassword && oldPassword)) ? false : true);
        }
    }, [isAdmin, isEdit, firstName, lastName, email, confirmEmail, password, newConfirmPassword, confirmPassword, oldPassword, termsAndConditions, setButtonDisabled, accountStatus, resetPassword]);

    // useEffect(() => {
    //     if (isAdmin && !isEdit) {
    //         // Admin New User
    //         if ((firstName && lastName && email && password && confirmPassword) && checkPasswordsMatch(password, confirmPassword)) {
    //             setButtonDisabled(false);
    //         } else {
    //             setButtonDisabled(true);
    //         }
    //         return;
    //     } else if (isAdmin && isEdit) {
    //         // Admin Edit User
    //         if (!firstName || !lastName || !email || !accountStatus) {
    //             setButtonDisabled(true);
    //             return;
    //         }
    //         if (resetPassword && (password && confirmPassword) && checkPasswordsMatch(password, confirmPassword)) {
    //             setButtonDisabled(false);
    //         } else if (!resetPassword) {
    //             setButtonDisabled(false);
    //         }
    //         else {
    //             setButtonDisabled(true);
    //         }
    //     } else if (!isAdmin && !isEdit) {
    //         // New User Sign Up
    //         if ((firstName && lastName && email && password && newConfirmPassword && termsAndConditions) && checkPasswordsMatch(password, newConfirmPassword) && (email === confirmEmail)) {
    //             setButtonDisabled(false);
    //         } else {
    //             setButtonDisabled(true);
    //         }
    //     } else {
    //         // User Edit Profile
    //         if (
    //             ((firstName && lastName && email && password && confirmPassword) && checkPasswordsMatch(password, confirmPassword)) ||
    //             (firstName && lastName && email && password && confirmPassword && oldPassword)
    //         ) {
    //             setButtonDisabled(false);
    //         } else {
    //             setButtonDisabled(true);
    //         }
    //     }
    // }, [isAdmin, isEdit, firstName, lastName, email, confirmEmail, password, newConfirmPassword, confirmPassword, oldPassword, termsAndConditions, setButtonDisabled, accountStatus, resetPassword]);

    const handleDateChange = (newDate:  dayjs.Dayjs | null) => {
        if (newDate && newDate.isValid() && setBirthday) {
            setBirthday(newDate.toISOString());
        }
    };

    return (
        <Grid container spacing={3} sx={{ ...sx }}>
            <FormGrid item xs={12} md={6}>
                <FormLabel htmlFor="first-name" required={!isEdit}>
                    First Name
                </FormLabel>
                <OutlinedInput
                    id="first-name"
                    name="first-name"
                    type="name"
                    placeholder="John"
                    autoComplete="first name"
                    defaultValue={isEdit ? firstName : undefined}
                    required={!isEdit}
                    onChange={(e) => { setFirstName(e.target.value) }}
                />
            </FormGrid>
            <FormGrid item xs={12} md={6}>
                <FormLabel htmlFor="last-name" required={!isEdit}>
                    Last Name
                </FormLabel>
                <OutlinedInput
                    id="last-name"
                    name="last-name"
                    type="last-name"
                    placeholder="Snow"
                    autoComplete="last name"
                    defaultValue={isEdit ? lastName : undefined}
                    required={!isEdit}
                    onChange={(e) => { setLastName(e.target.value) }}
                />
            </FormGrid>
            <FormGrid item xs={12}>
                <FormLabel htmlFor="date-of-birth">
                    Date Of Birth
                </FormLabel>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        value={birthday ? dayjs(birthday) : dayjs()}
                        onChange={(newDate) => handleDateChange(newDate)}
                    />
                </LocalizationProvider>
            </FormGrid>
            <FormGrid item xs={12}>
                <FormLabel htmlFor="email" required={!isEdit}>
                    Email
                </FormLabel>
                <OutlinedInput
                    id="email"
                    name="email"
                    type="email"
                    placeholder="user@imageshield.com"
                    autoComplete="email"
                    defaultValue={isEdit ? email : undefined}
                    required={!isEdit}
                    onChange={(e) => { setEmail(e.target.value) }}
                />
            </FormGrid>
            {(!isEdit && !isAdmin && setConfirmEmail) &&
                <FormGrid item xs={12}>
                    <FormLabel htmlFor="confirm-email" required={!isEdit}>
                        Confirm Email
                    </FormLabel>
                    <OutlinedInput
                        id="confirm-email"
                        name="confirm-email"
                        type="email"
                        placeholder="user@imageshield.com"
                        autoComplete="email"
                        required={!isEdit}
                        defaultValue={confirmEmail}
                        onChange={(e) => { setConfirmEmail(e.target.value) }}
                    />
                </FormGrid>
            }
            {isEdit &&
                <>
                    <FormGrid item xs={12}>
                        <FormControlLabel
                            label="Edit Password"
                            labelPlacement="end"
                            control={
                                <Switch
                                    checked={resetPassword}
                                    onChange={() => { setResetPassword(!resetPassword) }}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                            }
                        />
                    </FormGrid>

                    {!isAdmin && setOldPassword && resetPassword &&
                        <FormGrid item xs={12}>
                            <FormLabel htmlFor="password">
                                Old Password
                            </FormLabel>
                            <OutlinedInput
                                id="old-password"
                                name="password"
                                type={'password'}
                                placeholder="Old Password"
                                autoComplete="password"
                                onChange={(e) => { setOldPassword(e.target.value) }}
                            />
                        </FormGrid>
                    }
                    {resetPassword &&
                        <FormGrid item xs={12}>
                            <FormLabel htmlFor="password" required={!isEdit || oldPassword === undefined || oldPassword.length > 0}>
                                {'New Password'}
                            </FormLabel>
                            <OutlinedInput
                                id="new-password"
                                name="password"
                                type={showPassword ? 'text' : 'password'}
                                disabled={
                                    isAdmin ? false :
                                    (isEdit && (oldPassword === undefined || oldPassword.length === 0))
                                }
                                placeholder={'New Password'}
                                autoComplete="password"
                                required={!isEdit || !oldPassword || oldPassword.length > 0}
                                onChange={(e) => { setPassword(e.target.value) }}
                                endAdornment={
                                    <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                        <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                                    </IconButton>
                                }
                            />
                        </FormGrid>
                    }
                    {resetPassword &&
                        <>
                            <FormGrid item xs={12}>
                                <FormLabel htmlFor="confirm-password" required={!isEdit || password.length > 0}>
                                    Confirm Password
                                </FormLabel>
                                <OutlinedInput
                                    id="confirm-password"
                                    name="confirm-password"
                                    type={showConfirm ? 'text' : 'password'}
                                    disabled={password.length === 0}
                                    placeholder="Confirm Password"
                                    autoComplete="confirm-password"
                                    required={!isEdit || password.length > 0}
                                    onChange={(e) => {
                                        if (!isEdit && !isAdmin) {
                                            setNewConfirmPassword(e.target.value);
                                        } else if (setConfirmPassword) {
                                            setConfirmPassword(e.target.value);
                                        }
                                    }}
                                    endAdornment={
                                        <IconButton onClick={() => setShowConfirm(!showConfirm)} edge="end">
                                            <Iconify icon={showConfirm ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                                        </IconButton>
                                    }
                                />
                            </FormGrid>
                            <FormGrid item xs={12}>
                                {passwordError && <Alert severity="error">{passwordError}</Alert>}
                            </FormGrid>
                        </>
                    }
                </>
            }
            {!isEdit &&
                <>
                    <FormGrid item xs={12}>
                        <FormLabel htmlFor="password" required={!isEdit || oldPassword === undefined || oldPassword.length > 0}>
                            {isEdit ? 'New Password' : 'Password'}
                        </FormLabel>
                        <OutlinedInput
                            id="new-password"
                            name="password"
                            type={showPassword ? 'text' : 'password'}
                            disabled={
                                isEdit && (oldPassword === undefined || oldPassword.length === 0)
                            }
                            placeholder={isEdit ? 'New Password' : 'Password'}
                            autoComplete="password"
                            required={!isEdit || !oldPassword || oldPassword.length > 0}
                            onChange={(e) => { setPassword(e.target.value) }}
                            endAdornment={
                                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                                </IconButton>
                            }
                        />
                    </FormGrid>
                    <FormGrid item xs={12}>
                        <FormLabel htmlFor="confirm-password" required={!isEdit || password.length > 0}>
                            Confirm Password
                        </FormLabel>
                        <OutlinedInput
                            id="confirm-password"
                            name="confirm-password"
                            type={showConfirm ? 'text' : 'password'}
                            disabled={password.length === 0}
                            placeholder="Confirm Password"
                            autoComplete="confirm-password"
                            required={!isEdit || password.length > 0}
                            onChange={(e) => {
                                if (!isEdit && !isAdmin) {
                                    setNewConfirmPassword(e.target.value);
                                } else if (setConfirmPassword) {
                                    setConfirmPassword(e.target.value);
                                }
                            }}
                            endAdornment={
                                <IconButton onClick={() => setShowConfirm(!showConfirm)} edge="end">
                                    <Iconify icon={showConfirm ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                                </IconButton>
                            }
                        />
                    </FormGrid>
                    <FormGrid item xs={12}>
                        {passwordError && <Alert severity="error">{passwordError}</Alert>}
                    </FormGrid>
                </>
            }
            {isAdmin && setAccountStatus &&
                <>
                    <FormGrid item xs={12}>
                        <FormControl fullWidth>
                            <InputLabel id="account-status">Account Status</InputLabel>
                            <Select
                                input={<OutlinedInput label="Account Status" />}
                                label="Account Status"
                                labelId="account-status-label"
                                id="Account Status"
                                value={accountStatus}
                                onChange={(e) => { setAccountStatus(e.target.value as ACCOUNT_STATUS_CHOICES_TYPE) }}
                            >
                                {ACCOUNT_STATUS_CHOICES.map((status) => (
                                    <MenuItem
                                        key={status.name}
                                        value={status.name}
                                    >
                                        {capWords(status.name)}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </FormGrid>
                </>
            }
            {!isEdit && !isAdmin &&
                <FormGrid item xs={12}>
                    <Stack direction={'row'} alignItems={'center'}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name="T&C"
                                    checked={termsAndConditions}
                                    onChange={handleCheckboxChange}
                                />
                            }
                            label="Agree to terms and conditions"
                            required
                            sx={{ pr: 0, mr: 0 }}
                        />
                        <Box>
                            <IconButton size={'small'} color={'primary'} onClick={handleIconClick}>
                                <Iconify icon={'eva:link-2-outline'} />
                            </IconButton>
                        </Box>
                    </Stack>
                    <XDialog
                        open={tAndCOpen}
                        onClose={() => { setTAndCOpen(false) }}
                    >
                        <TermsConditions
                            setAcceptTerms={setTermsAndConditions}
                            setOpen={setTAndCOpen}
                        />
                    </XDialog>
                </FormGrid>
            }
        </Grid>
    );
}