import { ZENDESK_KEY } from "../config";
import Zendesk from "react-zendesk";
import { useTheme } from "@mui/material";

// ----------------------------------------------------------------------

export const ZendeskComp = () => {

    const theme = useTheme();

    const setting = {
        color: {
            theme: theme.palette.primary.main,
        },
        settings: {
            webWidget: {
                color: {
                    theme: theme.palette.primary.main,
                },
                offset: {
                    mobile: {
                        horizontal: '20px',
                        vertical: '50px',
                    },
                },
            }
        }
    };

    return (
        <Zendesk
            defer
            zendeskKey={ZENDESK_KEY}
            {...setting}
            onLoaded={() => console.log('Zendesk Loaded Successfully')}
        />
    );
};