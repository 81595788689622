/* eslint-disable react-hooks/exhaustive-deps */
import { Accordion, AccordionDetails, AccordionSummary, alpha, Fade, Grid, IconButton, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography, useTheme } from '@mui/material';
import { DataGrid, GridCellParams, GridColDef } from '@mui/x-data-grid';
import queryString from 'query-string';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useApiCall } from 'src/hooks/useApiCall';
import { useLocation, useNavigate } from 'react-router-dom';
import Page from 'src/components/base/Page';
import { formatDate } from 'src/utils/formatDate';
import Iconify from 'src/components/base/Iconify';
import { ProfileImageType, ReportType } from 'src/@types/types';
import LoadingBackdrop from 'src/components/base/LoadingBackdrop';
import { XDialog } from 'src/components/base/XDialog';
import { PATH_ADMIN } from 'src/routes/paths';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { TRANSITIONS } from 'src/config';
import { capWords } from 'src/utils/capWords';
import { copyToClipboard } from 'src/utils/copyToClipboard';
import { useSnackbar } from 'notistack';

// ----------------------------------------------------------------------

export default function ReportsList() {

    const location = useLocation();
    const navigate = useNavigate();
    const { apiCall } = useApiCall();

    const queryParams: queryString.ParsedQuery = queryString.parse(location.search);
    const queryParamsRef = useRef(queryParams);
    queryParamsRef.current = queryParams;

    const [reports, setReports] = useState<ReportType[]>([]);
    const [pageSize, setPageSize] = useState<number>(0);
    const [totalItems, setTotalItems] = useState<number>(0);
    const [totalPages, setTotalPages] = useState<number>(0);
    const [currentPage, setCurrentPage] = useState<number>(0);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [searchTerm, setSearchTerm] = useState<string>(queryParams.name as string || '');
    const [awaitingResponse, setAwaitingResponse] = useState<boolean>(false);
    const [viewedReport, setViewedReport] = useState<ReportType | null>(null);
  
    useEffect(() => {
        // Safely update searchTerm based on the URL's query parameter
        setSearchTerm(typeof queryParams.name === 'string' ? queryParams.name : '');
    }, [queryParams.name]);

    // Helper to update query parameters in the URL
    const updateQueryParams = useCallback((updates: Record<string, string | undefined>) => {
        const queryParams = queryString.parse(location.search);
        const newParams = { ...queryParams, ...updates };
        navigate(`${location.pathname}?${queryString.stringify(newParams)}`, { replace: true });
    }, [location, navigate]);

    const fetchInfo = useCallback(() => {
        setAwaitingResponse(true);
        let url = 'a/get_reports';
        const queryParams = queryString.parse(location.search);
        if (Object.keys(queryParams).length > 0) {
            url += `?${queryString.stringify(queryParams)}`;
        }
        apiCall(url, 'GET', null, (data) => {
            setTotalPages(data.total_pages);
            setCurrentPage(data.current_page);
            setPageSize(data.page_size);
            setTotalItems(data.total_items);
            setReports(data.results);
        }).then(() => {
            setAwaitingResponse(false);
        });
    }, [location.search, apiCall]);

    useEffect(() => { fetchInfo() }, [location.search]);

    const handlePageChange = (page: number) => {
        updateQueryParams({ page: (1 + page).toString() });
    };

    const columns: GridColDef[] = [
        {
            field: 'owner.user.email',
            headerName: 'Email',
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params) => params.row.owner.user.email,
            minWidth: 140,
            flex: 1,
        },
        {
            field: 'total_images_count',
            headerName: 'Total Images',
            type: 'number',
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params) => params.row.total_images_count,
            minWidth: 80
        },
        {
            field: 'completed_at',
            headerName: 'Complete',
            type: 'string',
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params) => params.row.completed_at ? 'Yes' : 'No',
            minWidth: 60
        },
        {
            field: 'cleared_images',
            headerName: 'Cleared',
            type: 'number',
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params) => params.row.cleared_images.length,
            minWidth: 60
        },
        {
            field: 'infringed_images',
            headerName: 'Abused',
            type: 'number',
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params) => params.row.infringed_images.length,
            minWidth: 60
        },
        {
            field: 'error_images',
            headerName: 'Errors',
            type: 'number',
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params) => params.row.error_images.length,
            minWidth: 60
        },
        {
            field: 'updated_at',
            headerName: 'Updated At',
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params) => formatDate(params.row.updated_at),
            minWidth: 120
        },
        {
            field: '',
            headerName: 'Actions',
            headerAlign: 'right',
            align: 'right',
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params) => (
                <>
                    <Tooltip
                        title="View Images"
                        arrow
                        enterDelay={500}
                        enterNextDelay={500}
                        enterTouchDelay={500}
                        TransitionComponent={Fade}
                        TransitionProps={{ timeout: 600 }}
                    >
                        <IconButton size={'small'} color={'primary'} onClick={() => {
                            navigate(PATH_ADMIN.photos + `?owner__id=${params.row.owner.id}`)
                        }}>
                            <Iconify icon={'ph:images'} />
                        </IconButton>
                    </Tooltip>
                    <Tooltip
                        title="View Details"
                        arrow
                        enterDelay={500}
                        enterNextDelay={500}
                        enterTouchDelay={500}
                        TransitionComponent={Fade}
                        TransitionProps={{ timeout: 600 }}
                    >
                        <IconButton size={'small'} color={'primary'} onClick={() => setViewedReport(params.row)}>
                            <Iconify icon={'eva:info-outline'} />
                        </IconButton>
                    </Tooltip>
                </>
            ),
            minWidth: 80
        }
    ];

    return (
        <Page title="Reports">
            <ReportDetails
                viewedReport={viewedReport}
                setViewedReport={setViewedReport}
            />
            {awaitingResponse && <LoadingBackdrop />}
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <DataGrid
                        filterMode={'server'}
                        paginationMode={'server'}
                        pageSizeOptions={[pageSize]}
                        paginationMeta={{
                            hasNextPage: currentPage < totalPages,
                        }}
                        onPaginationModelChange={(model) => handlePageChange(model.page)}
                        rowCount={totalItems}
                        estimatedRowCount={totalItems}
                        paginationModel={{
                            page: currentPage - 1,
                            pageSize: pageSize,
                        }}
                        rows={reports}
                        // loading={awaitingResponse}
                        columns={columns}
                        rowSelection={false}
                        getCellClassName={(params: GridCellParams<any, any, number>) => {
                            if (params.field === 'city' || params.value == null) {
                                return '';
                            }
                            return params.value >= 15 ? 'hot' : 'cold';
                        }}
                    />
                </Grid>
            </Grid>
        </Page>
    )
}

// ----------------------------------------------------------------------

type ReportDetailsProps = {
    viewedReport: ReportType | null;
    setViewedReport: (report: ReportType | null) => void;
}

function ReportDetails({ viewedReport, setViewedReport }: ReportDetailsProps) {

    const [clearedImages, setClearedImages] = useState<ProfileImageType[]>([]);
    const [abusedImages, setAbusedImages] = useState<ProfileImageType[]>([]);
    const [errorImages, setErrorImages] = useState<ProfileImageType[]>([]);

    useEffect(() => {
        if (viewedReport !== null) {
            setClearedImages(viewedReport.cleared_images);
            setAbusedImages(viewedReport.infringed_images);
            setErrorImages(viewedReport.error_images);
        } else {
            setClearedImages([]);
            setAbusedImages([]);
            setErrorImages([]);
        }
    }, [viewedReport]);

    return (
        <XDialog
            open={viewedReport !== null}
            onClose={() => { setViewedReport(null) }}
        >
            <Stack spacing={3}>
                {['abused', 'cleared', 'error'].map((type) => (
                    <ImageDetails
                        key={type}
                        type={type as 'abused' | 'cleared' | 'error'}
                        images={
                            type === 'abused' ? abusedImages :
                            type === 'cleared' ? clearedImages :
                            errorImages
                        }
                    />
                ))}
            </Stack>
        </XDialog>
    )
}

// ----------------------------------------------------------------------

type ImageDetailsProps = {
    type: 'abused' | 'cleared' | 'error';
    images: ProfileImageType[]
}    

function ImageDetails({ type, images }: ImageDetailsProps) {

    const { enqueueSnackbar } = useSnackbar();
    const theme = useTheme();
    const open_background_color = alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity);

    const [open, setOpen] = useState<boolean>(false);

    return (
        <Accordion
            disableGutters={true}
            expanded={open}
            sx={{ backgroundColor: 'transparent' }}
            slotProps={{ transition: { unmountOnExit: true } }}
        >
            <AccordionSummary
                onClick={() => setOpen(!open)}
                expandIcon={<ExpandMoreIcon />}
                sx={{
                    borderRadius: '8px',
                    transition: TRANSITIONS.accordion,
                    color: theme.palette.text.secondary,
                    backgroundColor: 'transparent',
                    ...(open && { bgcolor: open_background_color }),
                }}
            >
                <Typography sx={{ transition: TRANSITIONS.text, ...(open && { color: theme.palette.primary.main }) }}>
                    {capWords(type)} Images ({images.length})
                </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ pt: 3, backgroundColor: 'transparent' }}>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Ref ID</TableCell>
                                <TableCell align="right">Copy ID</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {images.map((row) => (
                                <TableRow key={'violator_' + row.id}>
                                    <TableCell>{row.admin_ref_id}</TableCell>
                                    <TableCell align="right">
                                        <Tooltip title="Copy ID to Clipboard" arrow>
                                            <IconButton
                                                size={'small'}
                                                color={'primary'}
                                                onClick={() => {
                                                    copyToClipboard(row.admin_ref_id);
                                                    enqueueSnackbar('ID copied to clipboard', { variant: 'success', autoHideDuration: 750 });
                                                }}
                                            >
                                                <Iconify icon={'eva:copy-outline'} />
                                            </IconButton>
                                        </Tooltip>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </AccordionDetails>
        </Accordion>
    )
}