import { useLocation, useNavigate } from 'react-router-dom';
// @mui
import { styled, useTheme } from '@mui/material/styles';
import { Box, Fade, Stack, AppBar, Toolbar, Tooltip, IconButton } from '@mui/material';
import Logout from '@mui/icons-material/Logout';
import Badge from '@mui/material/Badge';
import NotificationsIcon from '@mui/icons-material/Notifications';
// import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
// hooks
import useOffSetTop from 'src/hooks/useOffSetTop';
import useResponsive from 'src/hooks/useResponsive';
import useAuth from 'src/hooks/useAuth';
// utils
import cssStyles from '../../../utils/cssStyles';
import { logout } from 'src/utils/jwt';
// config
import { HEADER, NAVBAR } from '../../../config';
// components
import Logo from '../../../components/Logo';
import Iconify from 'src/components/base/Iconify';
import { IconButtonAnimate } from '../../../components/animate';
import { PATH_PAGE } from 'src/routes/paths';

// ----------------------------------------------------------------------

const tooltipStyle = {
    arrow: true,
    enterDelay: 500,
    enterNextDelay: 500,
    enterTouchDelay: 500,
    TransitionComponent: Fade,
    TransitionProps: { timeout: 600 },
    slotProps: {
        popper: {
        modifiers: [
                {
                name: 'offset',
                options: {
                        offset: [0, -10],
                    },
                },
            ],
        },
    },
};

// ----------------------------------------------------------------------

type RootStyleProps = {
    isCollapse: boolean;
    isOffset: boolean;
    verticalLayout: boolean;
};

const RootStyle = styled(AppBar, {
    shouldForwardProp: (prop) =>
        prop !== 'isCollapse' && prop !== 'isOffset' && prop !== 'verticalLayout',
    })<RootStyleProps>(({ isCollapse, isOffset, verticalLayout, theme }) => ({
    ...cssStyles(theme).bgBlur(),
    boxShadow: 'none',
    height: HEADER.MOBILE_HEIGHT,
    zIndex: theme.zIndex.appBar + 1,
    transition: theme.transitions.create(['width', 'height'], {
        duration: theme.transitions.duration.shorter,
    }),
    [theme.breakpoints.up('lg')]: {
        height: HEADER.DASHBOARD_DESKTOP_HEIGHT,
        width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH + 1}px)`,
        ...(isCollapse && {
            width: `calc(100% - ${NAVBAR.DASHBOARD_COLLAPSE_WIDTH}px)`,
        }),
        ...(isOffset && {
            height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
        }),
        ...(verticalLayout && {
            width: '100%',
            height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
            backgroundColor: theme.palette.background.default,
        }),
    },
}));

// ----------------------------------------------------------------------

type Props = {
    onOpenSidebar: VoidFunction;
    isCollapse?: boolean;
    verticalLayout?: boolean;
};

export default function DashboardHeader({
    onOpenSidebar,
    isCollapse = false,
    verticalLayout = false,
}: Props) {
  const isOffset = useOffSetTop(HEADER.DASHBOARD_DESKTOP_HEIGHT) && !verticalLayout;

  const isDesktop = useResponsive('up', 'lg');

  const location = useLocation();
  const locationIsReports = location.pathname === PATH_PAGE.reports;

  const navigate = useNavigate();
  const { currentProfile } = useAuth();
  const theme = useTheme();

  return (
        <RootStyle isCollapse={isCollapse} isOffset={isOffset} verticalLayout={verticalLayout}>
            <Toolbar
                sx={{
                    minHeight: '100% !important',
                    color: 'white',
                    bgcolor: theme.palette.primary.main,
                }}
            >
                {isDesktop && verticalLayout && <Logo sx={{ mr: 2.5 }} />}

                {!isDesktop && (
                    <IconButtonAnimate onClick={onOpenSidebar} sx={{ mr: 1, color: 'inherit' }}>
                        <Iconify icon="eva:menu-fill" />
                    </IconButtonAnimate>
                )}
                <Box sx={{ flexGrow: 1 }} />

                <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
                    <Tooltip title="Reports" {...tooltipStyle}>
                        <Badge badgeContent={locationIsReports ? 0 : currentProfile?.not_viewed_reports} color="secondary" sx={{
                            '& .MuiBadge-badge': {
                                top: 2,
                                right: 4,
                            },
                        }}>
                            <IconButtonAnimate shake={locationIsReports ? false : ((currentProfile?.not_viewed_reports || 0) > 0)} color="inherit" onClick={() => { navigate(PATH_PAGE.reports) }}>
                                <NotificationsIcon />
                            </IconButtonAnimate>
                        </Badge>
                    </Tooltip>
                    <Tooltip title="Logout" {...tooltipStyle}>
                        <IconButton color="inherit" onClick={logout}>
                            <Logout />
                        </IconButton>
                    </Tooltip>
                </Stack>
            </Toolbar>
        </RootStyle>
    );
}