import useAuth from 'src/hooks/useAuth';
import { Box, Button, Card, CardActions, CardContent, Divider, Grid, Stack, Typography, useTheme } from '@mui/material';
import Page from 'src/components/base/Page';
import { useEffect, useState } from 'react';
import { useApiCall } from 'src/hooks/useApiCall';
import { SubscriptionType } from 'src/@types/types';
import { StripePriceType } from 'src/@types/auth';
import { fCurrency } from 'src/utils/formatNumber';
import { capWords } from 'src/utils/capWords';
import { XDialog } from 'src/components/base/XDialog';
import LoadingBackdrop from 'src/components/base/LoadingBackdrop';
import SignupStepper from 'src/components/signup/SignupStepper';
import Iconify from 'src/components/base/Iconify';
import { useSnackbar } from 'notistack';

// ----------------------------------------------------------------------

export default function Subscription() {

    const { apiCall } = useApiCall();
    const { currentProfile } = useAuth();
    const theme = useTheme();

    const [awaitingResponse, setAwaitingResponse] = useState<boolean>(true);
    const [subscriptionOptions, setSubscriptionOptions] = useState<SubscriptionType[]>();
    const [cancelConfirmOpen, setCancelConfirmOpen] = useState<boolean>(false);

    const getSubscriptionOptions = () => {
        apiCall('get_subscription_options', "GET", null, (data) => {
            setSubscriptionOptions(data);
            setAwaitingResponse(false);
        });
    };

    useEffect(() => {
        getSubscriptionOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const product_descriptions = [
        'Initial scan and report on your where your face and the faces of family members are being used',
        'Regular, ongoing reports on where and how your faces appear',
        'Instant access to abuse-reporting tools on Facebook, Instagram and other popular sites and platforms',
        '24/7 monitoring of the online world'
    ];

    return (
        <Page title="Subscription">
            {awaitingResponse && <LoadingBackdrop />}
            <ConfirmCancelSubscription
                open={cancelConfirmOpen}
                setOpen={setCancelConfirmOpen}
            />
            <Grid container spacing={3} sx={{ display: 'flex', justifyContent: 'center' }}>
                {currentProfile?.account_status === 'unpaid' &&
                    <Grid item xs={12}>
                        <SignupStepper activeStep={2} />
                    </Grid>
                }
                {subscriptionOptions?.map((option) => (
                    <Grid item key={option.stripe_product_id} xs={12}>
                        <Grid container spacing={3} sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Grid item xs={12}>
                                <Typography variant="h4" sx={{ textAlign: 'center' }}>
                                    {option.name}
                                </Typography>
                                <Stack width={'100%'} alignItems={'center'} sx={{ pt: 2 }}>
                                    <Stack maxWidth={'65%'} spacing={1}>
                                        {product_descriptions.map((description, index) => (
                                            <Stack key={index} direction={'row'} spacing={0.5} alignItems="flex-start">
                                                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', pt: '3px' }}>
                                                    <Iconify
                                                        icon={'eva:checkmark-circle-outline'}
                                                        width={16}
                                                        height={16}
                                                        color={theme.palette.primary.main}
                                                    />
                                                </Box>
                                                <Typography variant="body2" paragraph sx={{ textAlign: 'left' }}>
                                                    {description}
                                                </Typography>
                                            </Stack>
                                        ))}
                                    </Stack>
                                </Stack>
                            </Grid>
                            {option.prices.map((price) => (
                                <Grid item key={price.stripe_price_id} xs={12} sm={6} md={4} lg={3} sx={{ display: 'flex' }}>
                                    <PriceCard
                                        stripePrice={price}
                                        awaitingResponse={awaitingResponse}
                                        setAwaitingResponse={setAwaitingResponse}
                                        setCancelConfirmOpen={setCancelConfirmOpen}
                                    />
                                </Grid>
                            ))}
                            {subscriptionOptions.indexOf(option) !== subscriptionOptions.length - 1 &&
                                <Grid item xs={12}>
                                    <Divider flexItem />
                                </Grid>
                            }
                        </Grid>
                    </Grid>
                ))}
            </Grid>
        </Page>
    );
}

// ----------------------------------------------------------------------

type PriceCardProps = {
    stripePrice: StripePriceType;
    awaitingResponse: boolean;
    setAwaitingResponse: (awaitingResponse: boolean) => void;
    setCancelConfirmOpen: (open: boolean) => void;
}

function PriceCard({ stripePrice, awaitingResponse, setAwaitingResponse, setCancelConfirmOpen }: PriceCardProps) {

    const { apiCall } = useApiCall();
    const { currentProfile, isPaid, initialize } = useAuth();
    const { enqueueSnackbar } = useSnackbar();

    const [isSubmitting, setIsSubmitting] = useState(false);

    const switch_subscription = async (new_subscription_id: string) => {
        setAwaitingResponse(true);
        const formData = new FormData();
        formData.append('stripe_price_id', new_subscription_id.toString());
        apiCall('switch_subscription', "POST", formData, (data) => {
            console.log(data);
            initialize({ access: '', refresh: '' });
            enqueueSnackbar('Subscription successfully updated', { variant: 'success' });
        }).then(() => {
            setAwaitingResponse(false);
        });
    };

    const getPaymentLink = async (price_id: string) => {
        setAwaitingResponse(true);
        apiCall(`get_stripe_checkout_link/${price_id}`, "GET", null, (data) => {            
            setAwaitingResponse(false);
            window.location.href = data.url;
        }).then(() => {
            setAwaitingResponse(false);
        });
    }

    function getSubscribeDisabled(option: StripePriceType) {
        if (!currentProfile || awaitingResponse || isSubmitting) return true;
        if (isPaid) {
            if (currentProfile?.account_status === 'canceling') return false;
            return currentProfile?.price?.stripe_price_id === option.stripe_price_id
                || ['trial', 'unpaid'].includes(currentProfile?.account_status || '');
        } else {
            return ['active', 'trial'].includes(currentProfile?.account_status || '')
                || !option.stripe_payment_link;
        }
    };

    function getCancelDisabled(option: StripePriceType) {
        if (currentProfile?.account_status === 'canceled') return true;
        return !currentProfile
            || awaitingResponse
            || isSubmitting
            || (currentProfile?.price?.stripe_price_id !== option.stripe_price_id && currentProfile?.account_status === 'active')
            || ['trial', 'unpaid', 'canceling'].includes(currentProfile?.account_status || '');
    };

    return (
        <Card
            sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
            }}
        >
            <CardContent>
                <Stack
                    direction="row"
                    alignItems={'center'}
                    justifyContent={'space-between'}
                    spacing={1}
                    sx={{ mb: 1 }}
                >
                    <Typography variant="h5" component="div">
                        {fCurrency(stripePrice.price, 2)}
                    </Typography>
                    {currentProfile?.price?.stripe_price_id === stripePrice.stripe_price_id &&
                        <>
                            {currentProfile?.account_status === 'active' &&
                                <Typography variant="body2" color='primary'>
                                    Current Plan
                                </Typography>
                            }
                            {['canceled', 'canceling'].includes(currentProfile?.account_status || '') &&
                                <Typography variant="body2" color='error'>
                                    Canceled
                                </Typography>
                            }
                        </>
                    }
                </Stack>
                <Typography variant="body2">
                    Billed {capWords(stripePrice.type)}
                </Typography>
                {stripePrice.description &&
                    <>
                        <Divider flexItem sx={{ my: 1 }} />
                        <Typography variant="body2" paragraph>
                            {stripePrice.description}
                        </Typography>
                    </>
                }
            </CardContent>
            <CardActions>
                <Button
                    size="small"
                    disabled={getSubscribeDisabled(stripePrice)}
                    onClick={() => {
                        if (isPaid && currentProfile?.account_status !== 'canceling') {
                            switch_subscription(stripePrice.stripe_price_id);
                        } else {
                            setIsSubmitting(true);
                            getPaymentLink(stripePrice.stripe_price_id);                            
                        }
                    }}
                >
                    {(isPaid && currentProfile?.account_status !== 'canceling') ? 'Switch' : 'Subscribe'}
                </Button>
                <Button
                    size="small"
                    disabled={getCancelDisabled(stripePrice)}
                    color='error'
                    onClick={() => {
                        setCancelConfirmOpen(true);
                    }}
                >
                    Cancel
                </Button>
            </CardActions>
        </Card>
    )
}

// ----------------------------------------------------------------------

type ConfirmCancelSubscriptionProps = {
    open: boolean;
    setOpen: (open: boolean) => void;
}

function ConfirmCancelSubscription({ open, setOpen }: ConfirmCancelSubscriptionProps) {

    const { apiCall } = useApiCall();

    const [awaitingResponse, setAwaitingResponse] = useState<boolean>(false);

    const unsubscribe = () => {
        setAwaitingResponse(true);
        apiCall('cancel_subscriptions', "POST", null, (data) => {
            console.log(data);
            window.location.reload();
        });
    };

    return (
        <XDialog
            open={open}
            onClose={() => { setOpen(false) }}
        >
            {awaitingResponse && <LoadingBackdrop />}
            <Stack spacing={3}>
                <Stack spacing={1} sx={{ alignItems: 'center' }}>
                    <Typography variant='h6'>
                        Warning
                    </Typography>
                    <Typography paragraph textAlign={'center'}>
                        Your images will no longer be protected. Are you sure you want to cancel your subscription?
                    </Typography>
                    <Typography paragraph textAlign={'center'} variant='body2'>
                        Your subscription will remain active until the end of the current billing period.
                    </Typography>
                </Stack>
                <Stack direction="row" spacing={2}>
                    <Button
                        onClick={() => { setOpen(false) }}
                        variant='contained'
                        fullWidth
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={unsubscribe}
                        variant='contained'
                        color='error'
                        fullWidth
                    >
                        Unsubscribe
                    </Button>
                </Stack>
            </Stack>
        </XDialog>
    )
}