/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Fade, Grid, IconButton, Stack, TextField, Tooltip } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import queryString from 'query-string';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useApiCall } from 'src/hooks/useApiCall';
import { useLocation, useNavigate } from 'react-router-dom';
import { AuthProfile } from 'src/@types/auth';
import Page from 'src/components/base/Page';
import { formatDate } from 'src/utils/formatDate';
import Iconify from 'src/components/base/Iconify';
import { capWords } from 'src/utils/capWords';
import LoadingBackdrop from 'src/components/base/LoadingBackdrop';
import { XDialog } from 'src/components/base/XDialog';
import { PATH_ADMIN } from 'src/routes/paths';
import AddressForm from 'src/components/signup/AddressForm';
import { ACCOUNT_STATUS_CHOICES_TYPE } from 'src/@types/choices';
import { fCurrency } from 'src/utils/formatNumber';

// ----------------------------------------------------------------------

export default function UsersList() {

    const location = useLocation();
    const navigate = useNavigate();
    const { apiCall } = useApiCall();

    const queryParams: queryString.ParsedQuery = queryString.parse(location.search);
    const queryParamsRef = useRef(queryParams);
    queryParamsRef.current = queryParams;

    const [users, setUsers] = useState<AuthProfile[]>([]);
    const [pageSize, setPageSize] = useState<number>(0);
    const [totalItems, setTotalItems] = useState<number>(0);
    const [totalPages, setTotalPages] = useState<number>(0);
    const [currentPage, setCurrentPage] = useState<number>(0);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [searchTerm, setSearchTerm] = useState<string>(queryParams.name as string || '');
    const [awaitingResponse, setAwaitingResponse] = useState<boolean>(false);
    const [selectedUser, setSelectedUser] = useState<AuthProfile | null>(null);

    const [editOpen, setEditOpen] = useState<boolean>(false);
    const [flagOpen, setFlagOpen] = useState<boolean>(false);

    useEffect(() => {
        // Safely update searchTerm based on the URL's query parameter
        setSearchTerm(typeof queryParams.name === 'string' ? queryParams.name : '');
    }, [queryParams.name]);

    // Helper to update query parameters in the URL
    const updateQueryParams = useCallback((updates: Record<string, string | undefined>) => {
        const queryParams = queryString.parse(location.search);
        const newParams = { ...queryParams, ...updates };
        navigate(`${location.pathname}?${queryString.stringify(newParams)}`, { replace: true });
    }, [location, navigate]);

    const fetchInfo = useCallback(() => {
        setAwaitingResponse(true);
        let url = 'a/get_profiles';
        const queryParams = queryString.parse(location.search);
        if (Object.keys(queryParams).length > 0) {
            url += `?${queryString.stringify(queryParams)}`;
        }
        apiCall(url, 'GET', null, (data) => {
            setTotalPages(data.total_pages);
            setCurrentPage(data.current_page);
            setPageSize(data.page_size);
            setTotalItems(data.total_items);
            setUsers(data.results);
        }).then(() => {
            setAwaitingResponse(false);
        });
    }, [location.search, apiCall]);

    useEffect(() => { fetchInfo() }, [location.search]);

    const handlePageChange = (page: number) => {
        updateQueryParams({ page: (1 + page).toString() });
    };

    const columns: GridColDef[] = [
        {
            field: 'user__email',
            headerName: 'Email',
            sortable: true,
            disableColumnMenu: true,
            renderCell: (params) => params.row.user.email,
            minWidth: 140,
            flex: 1,
        },
        {
            field: 'account_status',
            headerName: 'Status',
            sortable: true,
            disableColumnMenu: true,
            hideable: true,
            renderCell: (params) => capWords(params.row.account_status),
        },
        {
            field: 'email_confirmed',
            headerName: 'Email Confirmed',
            sortable: true,
            disableColumnMenu: true,
            renderCell: (params) => params.row.email_confirmed ? 'Yes' : 'No',
            width: 80
        },
        {
            field: 'price__type',
            headerName: 'Subscription',
            sortable: true,
            disableColumnMenu: true,
            renderCell: (params) => (
                params.row.user.is_staff ? 'Admin' :
                capWords(params.row.price?.type || 'None') || 'None'
            ),
            minWidth: 60
        },
        {
            field: 'balance',
            headerName: 'Balance',
            sortable: true,
            disableColumnMenu: true,
            renderCell: (params) => (
                params.row.user.is_staff ? 'Admin' : fCurrency(params.row.balance)
            ),
            minWidth: 60
        },
        {
            field: 'user__first_name',
            headerName: 'First Name',
            sortable: true,
            disableColumnMenu: true,
            renderCell: (params) => params.row.user.first_name,
            width: 80
        },
        {
            field: 'user__last_name',
            headerName: 'Last Name',
            sortable: true,
            disableColumnMenu: true,
            renderCell: (params) => params.row.user.last_name,
            width: 80
        },
        {
            field: 'total_images_count',
            headerName: 'Images',
            type: 'number',
            sortable: true,
            disableColumnMenu: true,
            renderCell: (params) => params.row.total_images_count,
            width: 80
        },
        {
            field: 'notes',
            headerName: 'Flag',
            sortable: true,
            disableColumnMenu: true,
            renderCell: (params) => (
                <Tooltip
                    title={
                        isFlagged(params.row) ? "Flag User" : "Unflag User"
                    }
                    arrow
                    enterDelay={500}
                    enterNextDelay={500}
                    enterTouchDelay={500}
                    TransitionComponent={Fade}
                    TransitionProps={{ timeout: 600 }}
                >
                    <IconButton
                        size={'small'}
                        color={
                            isFlagged(params.row) ? 'primary' : 'error'
                        }
                        onClick={() => { setSelectedUser(params.row); setFlagOpen(true) }}
                    >
                        <Iconify icon={`eva:flag-${isFlagged(params.row) ? 'outline' : 'fill'}`} />
                    </IconButton>
                </Tooltip>
            ),
            width: 60
        },
        {
            field: 'last_report_review',
            headerName: 'Last Report Review',
            sortable: true,
            disableColumnMenu: true,
            renderCell: (params) => formatDate(params.row.last_report_review),
            minWidth: 100
        },
        {
            field: 'user__last_login',
            headerName: 'Last Login',
            sortable: true,
            disableColumnMenu: true,
            renderCell: (params) => formatDate(params.row.user.last_login),
            minWidth: 100
        },
        {
            field: 'created_at',
            headerName: 'Created At',
            sortable: true,
            disableColumnMenu: true,
            renderCell: (params) => formatDate(params.row.created_at),
            minWidth: 100
        },
        {
            field: '',
            headerName: 'Actions',
            headerAlign: 'right',
            align: 'right',
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params) => (
                <>
                    <Tooltip
                        title="View Images"
                        arrow
                        enterDelay={500}
                        enterNextDelay={500}
                        enterTouchDelay={500}
                        TransitionComponent={Fade}
                        TransitionProps={{ timeout: 600 }}
                    >
                        <IconButton size={'small'} color={'primary'} onClick={() => {
                            navigate(PATH_ADMIN.photos + `?owner__id=${params.row.id}`)
                        }}>
                            <Iconify icon={'ph:images'} />
                        </IconButton>
                    </Tooltip>
                    <Tooltip
                        title="Edit"
                        arrow
                        enterDelay={500}
                        enterNextDelay={500}
                        enterTouchDelay={500}
                        TransitionComponent={Fade}
                        TransitionProps={{ timeout: 600 }}
                    >
                        <IconButton
                            size={'small'}
                            color={'primary'}
                            onClick={() => { setSelectedUser(params.row); setEditOpen(true) }}
                        >
                            <Iconify icon={'eva:edit-2-outline'} />
                        </IconButton>
                    </Tooltip>
                </>
            ),
            minWidth: 80
        }
    ];

    return (
        <Page title="Users">
            <FlagUser
                open={flagOpen}
                setOpen={setFlagOpen}
                allProfiles={users}
                setAllProfiles={setUsers}
                viewedProfile={selectedUser}
                setViewedProfile={setSelectedUser}
            />
            <EditUser
                open={editOpen}
                setOpen={setEditOpen}
                viewedProfile={selectedUser}
                setViewedProfile={setSelectedUser}
            />
            {awaitingResponse && <LoadingBackdrop />}
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <DataGrid
                        filterMode={'server'}
                        paginationMode={'server'}
                        pageSizeOptions={[pageSize]}
                        paginationMeta={{
                            hasNextPage: currentPage < totalPages,
                        }}
                        onPaginationModelChange={(model) => handlePageChange(model.page)}
                        rowCount={totalItems}
                        estimatedRowCount={totalItems}
                        paginationModel={{
                            page: currentPage - 1,
                            pageSize: pageSize,
                        }}
                        rows={users}
                        // loading={awaitingResponse}
                        columns={columns}
                        rowSelection={false}
                        onSortModelChange={(model) => {
                            if (model.length > 0) {
                                const sort_direction = model[0].sort === 'asc' ? '' : '-';
                                updateQueryParams({ sort: sort_direction + model[0].field });
                            } else {
                                updateQueryParams({ sort: undefined });
                            }
                        }}
                        // if a number and less than 
                    />
                </Grid>
            </Grid>
        </Page>
    )
}

// ----------------------------------------------------------------------

type FlagUserProps = {
    open: boolean;
    setOpen: (open: boolean) => void
    allProfiles: AuthProfile[];
    setAllProfiles: (profiles: AuthProfile[]) => void;
    viewedProfile: AuthProfile | null;
    setViewedProfile: (profile: AuthProfile | null) => void;
}

function FlagUser({ open, setOpen, allProfiles, setAllProfiles, viewedProfile, setViewedProfile }: FlagUserProps) {

    const { apiCall } = useApiCall();

    const [awaitingResponse, setAwaitingResponse] = useState<boolean>(false);
    const [note, setNote] = useState<string>(viewedProfile?.notes || '');

    useEffect(() => {
        setNote(viewedProfile?.notes || '');
    }, [viewedProfile]);

    function submitFlagInfo(profile_id: number) {
        setAwaitingResponse(true);

        const getFormData = () => {
            const formData = new FormData();
            formData.append('notes', note || '');

            return formData;
        };

        apiCall(`a/toggle_flagged/${profile_id}`, 'POST', getFormData(), (data) => {
            const old_profile = viewedProfile;
            const new_profile = data;
            const index = allProfiles.findIndex((p) => p?.id === old_profile?.id);
            const updated_profiles = [...allProfiles];
            updated_profiles[index] = new_profile;
            setAllProfiles(updated_profiles);
        }).then(() => {
            setAwaitingResponse(false);
            setViewedProfile(null);
        });
    }

    return (
        <>
            {awaitingResponse && <LoadingBackdrop />}
            <XDialog
                open={open && viewedProfile !== null && !awaitingResponse}
                onClose={() => { setOpen(false) }}
            >
                <Stack spacing={3}>
                    <TextField
                        fullWidth
                        label="Notes"
                        variant="outlined"
                        value={note}
                        onChange={(e) => { setNote(e.target.value) }}
                        multiline
                        rows={3}
                    />
                    <Stack direction={'row'} spacing={1}>
                        <Button
                            onClick={() => { setViewedProfile(null) }}
                            variant='outlined'
                            fullWidth
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={() => { submitFlagInfo(viewedProfile?.id || -1) }}
                            variant='contained'
                            fullWidth
                        >
                            {isFlagged(viewedProfile) ? 'Flag' : 'Unflag'}
                        </Button>
                    </Stack>
                </Stack>
            </XDialog>
        </>
    )
}

// ----------------------------------------------------------------------

function isFlagged(profile: AuthProfile) {
    if (profile === null) return false;
    if (profile.notes === null || profile.notes === undefined) {
        return true;
    } else {
        return false;
    }
};

// ----------------------------------------------------------------------

type EditUserProps = {
    open: boolean;
    setOpen: (open: boolean) => void;
    viewedProfile: AuthProfile | null;
    setViewedProfile: (profile: AuthProfile | null) => void;
};

function EditUser({ open, setOpen, viewedProfile, setViewedProfile }: EditUserProps) {

    const { apiCall } = useApiCall();

    const [awaitingResponse, setAwaitingResponse] = useState<boolean>(false);
    const [password, setPassword] = useState<string>('');
    const [confirmPassword, setConfirmPassword] = useState<string>('');
    const [buttonDisabled, setButtonDisabled] = useState<boolean>(true);

    function editUser() {
        setAwaitingResponse(true);
        const getFormData = () => {
            const formData = new FormData();
            formData.append('first_name', viewedProfile?.user.first_name || '');
            formData.append('last_name', viewedProfile?.user.last_name || '');
            formData.append('email', viewedProfile?.user.email || '');
            formData.append('account_status', viewedProfile?.account_status || '');
            if (password !== '') {
                formData.append('password', password);
            }

            return formData;
        };
        apiCall(`edit_profile/${viewedProfile?.id}`, 'POST', getFormData(), (data) => {
            console.log(data);
            window.location.reload();
        });
    };

    return (
        <>
            {awaitingResponse && <LoadingBackdrop />}
            <XDialog
                open={viewedProfile !== null && !awaitingResponse && open}
                onClose={() => { setOpen(false) }}
            >
                <Stack spacing={3} sx={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                    <AddressForm
                        isEdit
                        isAdmin
                        firstName={viewedProfile?.user.first_name || ''}
                        setFirstName={
                            (value: string) => {
                                if (viewedProfile) {
                                    viewedProfile.user.first_name = value;
                                    setViewedProfile(viewedProfile);
                                }
                            }
                        }
                        lastName={viewedProfile?.user.last_name || ''}
                        setLastName={
                            (value: string) => {
                                if (viewedProfile) {
                                    viewedProfile.user.last_name = value;
                                    setViewedProfile(viewedProfile);
                                }
                            }
                        }
                        email={viewedProfile?.user.email || ''}
                        setEmail={
                            (value: string) => {
                                if (viewedProfile) {
                                    viewedProfile.user.email = value;
                                    setViewedProfile(viewedProfile);
                                }
                            }
                        }
                        accountStatus={viewedProfile?.account_status || 'unpaid'}
                        setAccountStatus={
                            (value: ACCOUNT_STATUS_CHOICES_TYPE) => {
                                if (viewedProfile) {
                                    const updatedProfile = {
                                        ...viewedProfile,
                                        account_status: value
                                    };
                                    setViewedProfile(updatedProfile);
                                }
                            }
                        }
                        password={password}
                        setPassword={setPassword}
                        confirmPassword={confirmPassword}
                        setConfirmPassword={setConfirmPassword}
                        setButtonDisabled={setButtonDisabled}
                    />
                    <Stack direction="row" spacing={2}>
                        <Button
                            onClick={() => { setOpen(false) }}
                            variant='outlined'
                            fullWidth
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={editUser}
                            variant='contained'
                            fullWidth
                            disabled={buttonDisabled}
                        >
                            Save
                        </Button>
                    </Stack>
                </Stack>
            </XDialog>
        </>
    )
}