import { Button, Stack, Typography } from '@mui/material';
import { ProfileImageType } from 'src/@types/types';
import { useApiCall } from 'src/hooks/useApiCall';
import { useState } from 'react';
import LoadingBackdrop from '../base/LoadingBackdrop';
import ImageCard from './ImageCard';
import { XDialog } from '../base/XDialog';

// ----------------------------------------------------------------------

type DeleteConfirmPopoverProps = {
    image: ProfileImageType;
    open: boolean;
    setOpen: (open: boolean) => void;
}

export default function DeleteConfirmPopover({ image, open, setOpen }: DeleteConfirmPopoverProps) {

    const { apiCall } = useApiCall();

    const [awaitingResponse, setAwaitingResponse] = useState<boolean>(false);

    const deleteImage = () => {
        setAwaitingResponse(true);
        apiCall(`remove_image/${image.id}`, "GET", null, (data) => {
            console.log(data);
            window.location.reload();
        });
    };

    return (
        <XDialog
            open={open}
            onClose={() => { setOpen(false) }}
        >
            {awaitingResponse && <LoadingBackdrop />}
            <Stack spacing={3}>
                <Stack sx={{ alignItems: 'center' }}>
                    <Typography variant='h6'>
                        Warning
                    </Typography>
                    <Typography>
                        Are you sure you want to delete this image?
                    </Typography>
                </Stack>
                <ImageCard
                    image={image}
                    cardActions={<></>}
                />
                <Stack direction="row" spacing={2}>
                    <Button
                        onClick={() => { setOpen(false) }}
                        variant='contained'
                        fullWidth
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={deleteImage}
                        variant='contained'
                        color='error'
                        fullWidth
                    >
                        Confirm
                    </Button>
                </Stack>
            </Stack>
        </XDialog>
    )
}