// @mui
import { styled } from '@mui/material/styles';
import { List, Box, ListSubheader, Stack } from '@mui/material';
// type
import { NavSectionProps } from 'src/@types/nav';
//
import { NavListRoot } from './NavList';

// ----------------------------------------------------------------------

export const ListSubheaderStyle = styled((props: { children?: React.ReactNode; sx?: object }) => (
    <ListSubheader disableSticky disableGutters {...props}>
        {props.children}
    </ListSubheader>
))(({ theme }) => ({
    ...theme.typography.overline,
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    color: theme.palette.text.primary,
    transition: theme.transitions.create('opacity', {
        duration: theme.transitions.duration.shorter,
  }),
}));

// ----------------------------------------------------------------------

export default function NavSectionVertical({
    navConfig,
    isCollapse = false,
    ...other
}: NavSectionProps) {
    return (
        <Box {...other}>
            {navConfig.map((group, index) => (
                <List key={`${group.subheader}_nav_subheader_${index}`} disablePadding sx={{ px: 2 }}>
                    {group.subheader && (
                        <ListSubheaderStyle
                            sx={{
                                ...(isCollapse && {
                                    opacity: 0,
                                }),
                            }}
                        >
                            {group.subheader}
                        </ListSubheaderStyle>
                    )}

                    <Stack sx={{ pt: 1 }}>
                        {group.items.map((list) => (
                            <NavListRoot
                                key={list.title}
                                list={list}
                                isCollapse={isCollapse}
                            />
                        ))}
                    </Stack>
                </List>
            ))}
        </Box>
    );
}
