import { Navigate, useRoutes } from 'react-router-dom';
import { PATH_AFTER_LOGIN } from 'src/config';

// Guards
import GuestGuard from 'src/guards/GuestGuard';
import AuthGuard from 'src/guards/AuthGuard';
import AdminGuard from 'src/guards/AdminGuard';

// Layouts
import DashboardLayout from 'src/components/DashboardLayout';

// Misc Pages
import NotFound from 'src/pages/NotFound';
import Login from 'src/pages/landing_pages/Login';
import EmailVerify from 'src/pages/landing_pages/EmailVerify';
import ResetPassword from 'src/pages/landing_pages/ResetPassword';
import SignUp from 'src/pages/landing_pages/SignUp';

// Customer Pages
import Subscription from 'src/pages/customer_pages/Subscription';
import Account from 'src/pages/customer_pages/Account';
import Reports from 'src/pages/customer_pages/Reports';
import Photos from 'src/pages/customer_pages/Photos';
import BillingHistory from 'src/pages/customer_pages/BillingHistory';

// Admin Pages
import UsersList from 'src/pages/admin_pages/UsersList';
import AdminMgmt from 'src/pages/admin_pages/AdminMgmt';
import PhotosList from 'src/pages/admin_pages/PhotosList';
import ReportsList from 'src/pages/admin_pages/ReportsList';

// ----------------------------------------------------------------------

function withGuestGuard(element: JSX.Element) { return <GuestGuard>{element}</GuestGuard> };

export default function Router() {
  return useRoutes([
    {
      path: 'guest',
      children: [
        { path: 'login', element: withGuestGuard(<Login />) },
        { path: 'signup', element: withGuestGuard(<SignUp />) },
        { path: 'verify-email', element: withGuestGuard(<EmailVerify />) },
        { path: 'reset-password', element: withGuestGuard(<ResetPassword />) },
      ],
    },
    {
      path: '',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'photos', element: <Photos /> },
        { path: 'subscription', element: <Subscription /> },
        { path: 'reports', element: <Reports /> },
        { path: 'billing_history', element: <BillingHistory /> },
        { path: 'account', element: <Account /> },

        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    {
      path: 'admin',
      element: (
        <AuthGuard>
          <AdminGuard>
            <DashboardLayout/>
          </AdminGuard>
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'manage_admins', element: <AdminMgmt /> },
        { path: 'photos', element: <PhotosList /> },
        { path: 'reports', element: <ReportsList /> },
        { path: 'users', element: <UsersList /> },
      ],
    },
    {
      path: '/',
      children: [
        { element: <Navigate to="/photos" replace />, index: true },
        { element: withGuestGuard(<Navigate to={PATH_AFTER_LOGIN} replace />), index: true },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
