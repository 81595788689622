import { Stack, Step, StepLabel, Stepper, Typography } from "@mui/material";

// ----------------------------------------------------------------------

type StepProps = {
    activeStep: number;
}

export default function SignupStepper({ activeStep }: StepProps) {

    const steps = ['Create Account', 'Verify Email', 'Payment', 'Upload Photos'];

    return (
        <Stack spacing={1}>
            <Stepper
                id="desktop-stepper"
                activeStep={activeStep}
                sx={{
                    width: '100%',
                    height: 40,
                }}
            >
                {steps.map((label) => (
                <Step
                    sx={{
                        ':first-of-type': { pl: 0 },
                        ':last-of-type': { pr: 0 },
                    }}
                    key={label}
                >
                    <StepLabel>{label}</StepLabel>
                </Step>
                ))}
            </Stepper>
            {activeStep === 3 &&
                <>
                    <Typography variant="body2" paragraph sx={{ textAlign: 'center' }}>
                        Upload any number of photos featuring your face and the faces of your family members.  The more photos you upload, the better ImageShield will be able to search the online world for the possible abuse of your faces.  Clear, recent photos, with faces looking straight on or in profile, are best.  As much as possible exclude photos that contain the faces of other people in them.
                    </Typography>
                    <Typography variant="body2" paragraph sx={{ textAlign: 'center' }}>
                        Note: the photos you upload will remain completely secure and private.  No one at ImageShield will be able to see or access them.
                    </Typography>
                </>
            }
        </Stack>
    )
}