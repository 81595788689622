import React from 'react';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { DialogProps } from '@mui/material/Dialog';
import { Box, DialogContent, useTheme } from '@mui/material';
import useResponsive from 'src/hooks/useResponsive';

interface XDialogProps extends DialogProps {
  onClose: () => void;
  sx?: object;
}

export const XDialog: React.FC<XDialogProps> = ({ onClose, sx, children, ...otherProps }) => {

  const theme = useTheme();

  const isMdOrLargerScreen = useResponsive('up', 'md');

  return (
    <Dialog
      onClose={onClose}
      {...otherProps}
      sx={{
        backgroundColor: 'rgba(0, 0, 0, 0.75)',
        color: theme.palette.primary.main,
        zIndex: (theme: { zIndex: { drawer: number; }; }) => theme.zIndex.drawer + 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '.MuiDialog-paperFullScreen': { backgroundColor: 'transparent' },
        '.MuiDialog-container': { width: '100%' },
        ...sx
      }}
      scroll={"body"}
      maxWidth={isMdOrLargerScreen ? 'sm' : 'xs'}
      fullWidth={true}
    >
      <Box sx={{ position: 'relative' }}>
          <IconButton
              aria-label="close"
              onClick={onClose}
              sx={{
                  position: 'absolute',
                  right: 2,
                  top: 2,
                  color: (theme) => theme.palette.grey[500],
                  zIndex: 10000
              }}
          >
              <CloseIcon />
          </IconButton>
      </Box>
      <DialogContent sx={{ p: 4 }}>
        {children}
      </DialogContent>
    </Dialog>
  );
};