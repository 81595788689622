import { Fade, Grid, IconButton, Stack, ToggleButton, ToggleButtonGroup, Tooltip, Typography } from "@mui/material"
import { capWords } from "src/utils/capWords";
import { STATUS_CHOICES } from 'src/pages/customer_pages/Photos';
import { useLocation } from "react-router-dom";
import queryString from 'query-string';
import { IMAGE_STATUS_CHOICES_TYPE } from "src/@types/choices";
import { useContext } from "react";
import { MetadataContext } from "src/contexts/MetadataContext";

// ----------------------------------------------------------------------

type FilterButtionsProps = {
    updateQueryParams: (params: { status?: string | undefined, page?: string | undefined }) => void;
};

export default function FilterButtions({ updateQueryParams }: FilterButtionsProps) {

    const { isMobile } = useContext(MetadataContext);

    const location = useLocation();

    const handleStatusChange = (event: React.MouseEvent<HTMLElement>, status: IMAGE_STATUS_CHOICES_TYPE) => {
        updateQueryParams({
            status: status === 'all' ? undefined : status, page: '1'
        });
    };

    return (
        <Grid item xs={12} sm={8} md={9} lg={5}>
            <ToggleButtonGroup
                value={queryString.parse(location.search).status as IMAGE_STATUS_CHOICES_TYPE || 'all'}
                color="primary"
                exclusive
                onChange={(event, value) => { handleStatusChange(event, value as IMAGE_STATUS_CHOICES_TYPE) }}
                aria-label="status"
                size="small"
                fullWidth
            >
                {STATUS_CHOICES.map((status) => {
                    if (status.display_name !== null) {
                        return (
                            <Tooltip
                                key={status.name}
                                title={capWords(status.display_name)}
                                arrow
                                enterDelay={500}
                                enterNextDelay={500}
                                enterTouchDelay={500}
                                TransitionComponent={Fade}
                                TransitionProps={{ timeout: 600 }}
                                slotProps={{
                                    popper: {
                                        modifiers: [
                                            {
                                                name: 'offset',
                                                options: {
                                                    offset: [0, -18],
                                                },
                                            },
                                        ],
                                    },
                                }}
                            >
                                <ToggleButton value={status.name}>
                                    <Stack>
                                        <IconButton color={status.color as any}>
                                            {status.icon}
                                        </IconButton>
                                        {isMobile &&
                                            <Typography variant="caption">
                                                {status.mobile_name}
                                            </Typography>
                                        }
                                    </Stack>
                                </ToggleButton>
                            </Tooltip>
                        )
                    } else {
                        return null;
                    }
                })}
            </ToggleButtonGroup>
        </Grid>
    )
}