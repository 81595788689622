import { Props as ScrollbarProps } from 'simplebar-react';
// @mui
import { Box, SxProps } from '@mui/material';

// ----------------------------------------------------------------------

interface Props extends ScrollbarProps {
  isMobile: boolean;
  sx?: SxProps;
}

export default function Scrollbar({ isMobile, children, sx, ...other }: Props) {

  return (
    <Box sx={{ overflowX: 'auto', ...sx }} {...other}>
      <>{children}</>
    </Box>
  );
}
