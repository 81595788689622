import { Button, CardActions, Grid } from '@mui/material';
import { ProfileImageType } from 'src/@types/types';
import { useState } from 'react';
import ImageCard from './ImageCard';
import DeleteConfirmPopover from './DeleteConfirmPopover';
import MainDashImgPopover from './MainDashImgPopover';

// ----------------------------------------------------------------------

type MainDashImgHandlerProps = {
    image: ProfileImageType
}

export default function MainDashImgHandler({ image }: MainDashImgHandlerProps) {

    const [open, setOpen] = useState<boolean>(false);
    const [deleteConfirmOpen, setDeleteConfirmOpen] = useState<boolean>(false);

    return (
        <Grid item xs={6} md={4} lg={3}>
            <ImageCard
                image={image}
                preview={true}
                imageClick={() => { setOpen(true) }}
                cardActions={
                    <CardActions>
                        <Button
                            size="small"
                            onClick={() => { setOpen(true) }}
                        >
                            Manage
                        </Button>
                    </CardActions>
                }
            />
            <MainDashImgPopover
                image={image}
                open={open}
                setOpen={setOpen}
                setDeleteConfirmOpen={setDeleteConfirmOpen}
            />
            <DeleteConfirmPopover
                image={image}
                open={deleteConfirmOpen}
                setOpen={setDeleteConfirmOpen}
            />
        </Grid>
    );
}