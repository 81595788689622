import { Button, Fade, IconButton, InputAdornment, Stack, TextField, Tooltip } from '@mui/material';
import { ProfileImageType } from 'src/@types/types';
import ImageCard from './ImageCard';
import { XDialog } from '../base/XDialog';
import Iconify from '../base/Iconify';
import { copyToClipboard } from 'src/utils/copyToClipboard';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import LoadingBackdrop from '../base/LoadingBackdrop';
import { MAIN_API } from 'src/config';

// ----------------------------------------------------------------------

type MainDashImgPopoverProps = {
    image: ProfileImageType;
    open: boolean;
    setOpen: (open: boolean) => void;
    setDeleteConfirmOpen: (open: boolean) => void;
}

export default function MainDashImgPopover({ image, open, setOpen, setDeleteConfirmOpen }: MainDashImgPopoverProps) {

    const { enqueueSnackbar } = useSnackbar();

    const [awaitingResponse, setAwaitingResponse] = useState<boolean>(false);

    const downloadImage = () => {
        setAwaitingResponse(true);
        window.open(`${MAIN_API.base_url}download_image/${image.id}`, '_blank');
        setAwaitingResponse(false);
    }

    return (
        <XDialog
            open={open}
            onClose={() => { setOpen(false) }}
        >
            {awaitingResponse && <LoadingBackdrop />}
            <Stack spacing={3}>
                <ImageCard
                    image={image}
                    cardActions={<></>}
                    imageClick={() => window.open(image.img_url, '_blank')}
                />
                <TextField
                    fullWidth
                    label="Reference ID"
                    variant="outlined"
                    defaultValue={image.admin_ref_id}
                    disabled
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <Tooltip
                                    title="Copy to Clipboard"
                                    arrow
                                    enterDelay={500}
                                    enterNextDelay={500}
                                    enterTouchDelay={500}
                                    TransitionComponent={Fade}
                                    TransitionProps={{ timeout: 600 }}
                                    slotProps={{
                                        popper: {
                                            modifiers: [
                                                {
                                                    name: 'offset',
                                                    options: {
                                                        offset: [0, -10],
                                                    },
                                                },
                                            ],
                                        },
                                    }}
                                >
                                    <IconButton
                                        onClick={() => {
                                            copyToClipboard(image.admin_ref_id);
                                            enqueueSnackbar('ID copied to clipboard', { variant: 'success', autoHideDuration: 750 });
                                        }}
                                        edge="end"
                                    >
                                        <Iconify icon={'eva:copy-outline'} />
                                    </IconButton>
                                </Tooltip>
                            </InputAdornment>
                        ),
                    }}
                />
                <Stack direction="row" spacing={2}>
                    <Button
                        onClick={downloadImage}
                        variant='contained'
                        color='primary'
                        fullWidth
                    >
                        Download
                    </Button>
                    <Button
                        onClick={() => {
                            setOpen(false);
                            setDeleteConfirmOpen(true);
                        }}
                        variant='contained'
                        color='error'
                        fullWidth
                    >
                        Delete
                    </Button>
                </Stack>
            </Stack>
        </XDialog>
    )
}