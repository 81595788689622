import { useState, useEffect } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { isValidToken, refreshAccessToken, setSession } from '../utils/jwt';
import useAuth from 'src/hooks/useAuth';
import LoadingBackdrop from 'src/components/base/LoadingBackdrop';
import { PATH_GUEST, PATH_PAGE } from 'src/routes/paths';

// ----------------------------------------------------------------------

type AuthGuardProps = {
  children: JSX.Element;
};

// ----------------------------------------------------------------------

export default function AuthGuard({ children }: AuthGuardProps): JSX.Element | null {
  const { isAuthenticated, isInitialized, isAdmin, isPaid, currentProfile, initialize } = useAuth();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [loading, setLoading] = useState(true);
  const [requestedLocation, setRequestedLocation] = useState<string | null>(null);

  useEffect(() => {
    if (isInitialized && !currentProfile) {
      const accessToken = localStorage.getItem('accessToken') || '';
      const refreshToken = localStorage.getItem('refreshToken') || '';
      initialize({ access: accessToken, refresh: refreshToken });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInitialized, currentProfile, pathname]);

  useEffect(() => {
    const accessToken = localStorage.getItem('accessToken') || '';
    const refreshToken = localStorage.getItem('refreshToken') || '';
    initialize({ access: accessToken, refresh: refreshToken });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  useEffect(() => {
    const checkAuth = async () => {
      if (!isInitialized) {
        setLoading(true);
        return;
      }

      if (!isAuthenticated) {
        const refresh = localStorage.getItem('refreshToken');
        if (refresh) {
          try {
            const res = await refreshAccessToken(refresh);
            setSession(res.access, res.refresh);
            const newToken = localStorage.getItem('accessToken') || '';
            if (!isValidToken(newToken)) {
              handleInvalidToken();
            } else {
              setLoading(false);
            }
          } catch (error) {
            handleInvalidToken();
          }
        } else {
          handleInvalidToken();
        }
      } else {
        setLoading(false);
      }
    };

    const handleInvalidToken = () => {
      if (pathname !== requestedLocation) {
        setRequestedLocation(pathname);
      }
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      setLoading(false);
      navigate(PATH_GUEST.login);
    };

    checkAuth();
  }, [isInitialized, isAuthenticated, pathname, requestedLocation, navigate]);

  if (loading) {
    return <LoadingBackdrop />;
  }

  if (!isAdmin && (!isPaid && ![PATH_PAGE.subscription, PATH_PAGE.billing_history, PATH_PAGE.account].includes(pathname))) {
    navigate(PATH_PAGE.subscription);
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return children;
}