import { PATH_PAGE, PATH_ADMIN } from 'src/routes/paths';

import DashboardIcon from '@mui/icons-material/Dashboard';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import LayersIcon from '@mui/icons-material/Layers';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import DescriptionIcon from '@mui/icons-material/Description';
import { NavConfigProps } from 'src/@types/nav';
// import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';

export const navItems: NavConfigProps = {
    items: [
        { title: 'Photos', path: PATH_PAGE.photos, icon: <DashboardIcon /> },
        { title: 'Reports', path: PATH_PAGE.reports, icon: <LayersIcon /> },
        { title: 'Subscription', path: PATH_PAGE.subscription, icon: <ShoppingCartIcon /> },
        { title: 'Billing History', path: PATH_PAGE.billing_history, icon: <DescriptionIcon /> },
        { title: 'Account', path: PATH_PAGE.account, icon: <AccountBoxIcon /> },
    ]
};

export const adminNavItems: NavConfigProps = {
    subheader: 'Admin',
    items: [
        { title: 'Photos', path: PATH_ADMIN.photos, icon: <DashboardIcon /> },
        { title: 'Reports', path: PATH_ADMIN.reports, icon: <LayersIcon /> },
        { title: 'Users', path: PATH_ADMIN.users, icon: <AccountBoxIcon /> },
        { title: 'Admins', path: PATH_ADMIN.manage_admins, icon: <AdminPanelSettingsIcon /> },
    ]
};