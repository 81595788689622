import { Button, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import UploadAvatarComp, { FileWithPreview } from '../upload/UploadAvatarComp';
import { useApiCall } from 'src/hooks/useApiCall';
import LoadingBackdrop from '../base/LoadingBackdrop';
import { XDialog } from '../base/XDialog';

// ----------------------------------------------------------------------

type UploadPopoverProps = {
    open: boolean,
    setOpen: (open: boolean) => void
};

export default function UploadPopover({ open, setOpen }: UploadPopoverProps) {

    const { apiCall } = useApiCall();

    const [awaitingResponse, setAwaitingResponse] = useState<boolean>(false);

    const [uploadFiles, setUploadFiles] = useState<FileWithPreview[]>([]);

    useEffect(() => { setUploadFiles([]) }, [open]);

    const uploadContent = () => {
        setAwaitingResponse(true);
        const getFormData = () => {
            const formData = new FormData();
            uploadFiles.forEach((file) => {
                formData.append('img_files', file);
            });
            return formData;
        };
        apiCall('upload', "POST", getFormData(), (data) => {
            console.log(data);
            window.location.reload();
        });
    };

    return (
        <XDialog
            open={open}
            onClose={() => { setOpen(false) }}
        >
            {awaitingResponse && <LoadingBackdrop />}
            <Stack spacing={3}>
                <UploadAvatarComp
                    uploadFiles={uploadFiles}
                    setUploadFiles={setUploadFiles}
                    multiple={true}
                />
                <Button
                    onClick={uploadContent}
                    variant='contained'
                    disabled={
                        uploadFiles.length === 0 ||
                        uploadFiles.some((file) => file.size > 10000000) ||
                        uploadFiles.length > 10
                    }
                >
                    Upload
                </Button>
            </Stack>
        </XDialog>
    )
}