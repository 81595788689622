// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_GUEST = '/guest';
const ROOTS_ADMIN = '/admin';

// ----------------------------------------------------------------------

export const PATH_GUEST = {
  root: ROOTS_GUEST,
  login: path(ROOTS_GUEST, '/login'),
  signup: path(ROOTS_GUEST, '/signup'),
  verify_email: path(ROOTS_GUEST, '/verify-email'),
  resetPassRequest: path(ROOTS_GUEST, '/reset-password'),
};

export const PATH_PAGE = {
  photos: '/photos',
  subscription: '/subscription',
  personal_info: '/personal_info',
  reports: '/reports',
  billing_history: '/billing_history',
  account: '/account',
  page404: '/404',
};

export const PATH_ADMIN = {
  root: ROOTS_ADMIN,
  manage_admins: path(ROOTS_ADMIN, '/manage_admins'),
  photos: path(ROOTS_ADMIN, '/photos'),
  reports: path(ROOTS_ADMIN, '/reports'),
  users: path(ROOTS_ADMIN, '/users'),
};
