import { Button, Container, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Iconify from "src/components/base/Iconify";
import Page from "src/components/base/Page";
import SignupStepper from "src/components/signup/SignupStepper";
import { MAIN_LOGO } from "src/config";
import { useApiCall } from "src/hooks/useApiCall";
import { PATH_GUEST, PATH_PAGE } from "src/routes/paths";

// ----------------------------------------------------------------------

export default function EmailVerify() {

    const location = useLocation();
    const navigate = useNavigate();
    const { apiCall } = useApiCall();

    // get 'uid' and 'token' from URL
    const [uid, setUid] = useState<string>('');
    const [token, setToken] = useState<string>('');
    const [success, setSuccess] = useState<boolean>(false);
    const [awaitingResponse, setAwaitingResponse] = useState<boolean>(true);

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        setUid(queryParams.get('uid') || '');
        setToken(queryParams.get('token') || '');
    }, [location.search]);

    useEffect(() => {
        const verifyEmail = async () => {
            if (!uid || !token) { return };
            const formData = new FormData();
            formData.append('uid', uid);
            formData.append('token', token);

            apiCall('verify_email/', "POST", formData, (data) => {
                setAwaitingResponse(false);
                setSuccess(true);
                localStorage.setItem('accessToken', data.access);
                localStorage.setItem('refreshToken', data.refresh);
            }).catch((error) => {
                console.log(error);
                setAwaitingResponse(false);
                setSuccess(false);
            });
        }
        verifyEmail();
    }, [uid, token, apiCall]);

    return (
        <Page title="Verify Email">
            <Container maxWidth="sm" sx={{ py: 2 }}>
                <Stack sx={{ justifyContent: 'center', alignItems: 'center', my: 2 }} direction="row" spacing={1}>
                    <img
                        src={MAIN_LOGO}
                        alt="IS Logo"
                        onClick={() => { navigate(PATH_GUEST.login) }}
                        style={{ cursor: 'pointer' }}
                    />
                </Stack>
                <Stack spacing={1} width={'100%'} alignItems={'center'}>
                    <SignupStepper activeStep={!success ? 1 : 2} />
                    <Stack spacing={2} justifyContent={'center'} sx={{ height: '50vh' }}>
                        {awaitingResponse ?
                            <>
                                <Stack direction={'row'} justifyContent={'center'} alignItems={'center'}>
                                    <Iconify
                                        icon={'svg-spinners:3-dots-move'}
                                        width={80}
                                        height={80}
                                    />
                                </Stack>
                            </> :
                            <>
                                <Stack direction={'row'} justifyContent={'center'} alignItems={'center'}>
                                    <Iconify
                                        icon={success ? 'line-md:check-all' : 'line-md:alert'}
                                        width={80}
                                        height={80}
                                    />
                                </Stack>
                            </>
                        }
                        <Button
                            color="primary"
                            onClick={() => navigate(PATH_PAGE.subscription)}
                            variant='contained'
                            disabled={awaitingResponse}
                        >
                            {awaitingResponse ? 'Verifying' : 'Continue'}
                        </Button>
                    </Stack>
                </Stack>
            </Container>
        </Page>
    )
}