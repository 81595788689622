/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, ReactNode } from "react";
// import { MAIN_API } from "src/config";
// import axios from "axios";
// import { useSnackbar } from "notistack";

type Props = { children: ReactNode };

export const MetadataContext = createContext<{
    isMobile: boolean;
}>
({
    isMobile: false,
});

export default function MetadataProvider({ children }: Props) {

    // const { enqueueSnackbar } = useSnackbar();

    const userAgent = typeof navigator === 'undefined' ? 'SSR' : navigator.userAgent;

    const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);

    return (
        <MetadataContext.Provider
            value={{
                isMobile,
            }}
        >
            {children}
        </MetadataContext.Provider>
    );
}

// ----------------------------------------------------------------------