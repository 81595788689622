export type PRICE_CHOICES_TYPE = 'monthly' | 'yearly';
export type IMAGE_STATUS_CHOICES_TYPE = 'all' | 'pending' | 'protected' | 'abused' | 'error';
export type PRIORITY_CHOICES_TYPE = 'all' | 'low' | 'medium' | 'high';
export type ACCOUNT_STATUS_CHOICES_TYPE = 'active' | 'past_due' | 'unpaid' | 'canceling' | 'canceled' | 'trial';

export const ACCOUNT_STATUS_CHOICES = [
    { name: 'active', color: 'success' },
    { name: 'past_due', color: 'warning' },
    { name: 'unpaid', color: 'error' },
    { name: 'canceling', color: 'info' },
    { name: 'canceled', color: 'warning' },
    { name: 'trial', color: 'info' },
] as const;