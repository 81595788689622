import { Button, Stack, Typography } from '@mui/material';
import { useApiCall } from 'src/hooks/useApiCall';
import { useState } from 'react';
import LoadingBackdrop from '../base/LoadingBackdrop';
import { XDialog } from './XDialog';

// ----------------------------------------------------------------------

type DeleteConfirmPopoverProps = {
    url: string;
    open: boolean;
    setOpen: (open: boolean) => void;
}

export default function DeleteConfirmPopover({ url, open, setOpen }: DeleteConfirmPopoverProps) {

    const { apiCall } = useApiCall();

    const [awaitingResponse, setAwaitingResponse] = useState<boolean>(false);

    const deleteItem = () => {
        setAwaitingResponse(true);
        apiCall(url, "GET", null, (data) => {
            console.log(data);
            window.location.reload();
        });
    };

    return (
        <XDialog
            open={open}
            onClose={() => { setOpen(false) }}
        >
            {awaitingResponse && <LoadingBackdrop />}
            <Stack spacing={3}>
                <Stack sx={{ alignItems: 'center' }}>
                    <Typography variant='h6'>
                        Warning
                    </Typography>
                    <Typography paragraph sx={{ textAlign: 'center' }}>
                        This action is irreversible. Are you sure you want to delete this item?
                    </Typography>
                </Stack>
                <Stack direction="row" spacing={2}>
                    <Button
                        onClick={() => { setOpen(false) }}
                        variant='contained'
                        fullWidth
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={deleteItem}
                        variant='contained'
                        color='error'
                        fullWidth
                    >
                        Confirm
                    </Button>
                </Stack>
            </Stack>
        </XDialog>
    )
}