import { Outlet } from 'react-router-dom';
import { useContext, useState } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

import NavbarVertical from './layout/navbar/NavbarVertical';
import useCollapseDrawer from 'src/hooks/useCollapseDrawer';
import DashboardHeader from './layout/header/DashboardHeader';
import { HEADER, NAVBAR } from 'src/config';
import { MetadataContext } from 'src/contexts/MetadataContext';
import { ZendeskComp } from './Zendesk';

// ----------------------------------------------------------------------

type MainStyleProps = {
    collapseClick: boolean;
};

const MainStyle = styled('main', {
    shouldForwardProp: (prop) => prop !== 'collapseClick',
})<MainStyleProps>(({ collapseClick, theme }) => ({
    flexGrow: 1,
    paddingTop: HEADER.MOBILE_HEIGHT + 24,
    paddingBottom: HEADER.MOBILE_HEIGHT + 24,
    [theme.breakpoints.down('lg')]: {
        paddingLeft: 4,
        paddingRight: 4,
    },
    [theme.breakpoints.up('lg')]: {
        paddingLeft: 16,
        paddingRight: 16,
        paddingTop: HEADER.DASHBOARD_DESKTOP_HEIGHT + 24,
        paddingBottom: 0,
        width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH}px)`,
        transition: theme.transitions.create('margin-left', {
            duration: theme.transitions.duration.shorter,
        }),
        ...(collapseClick && {
            marginLeft: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
        }),
    },
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
    
    const { isMobile } = useContext(MetadataContext);

    const [open, setOpen] = useState<boolean>(isMobile ? false : true);

    const { collapseClick, isCollapse, onToggleCollapse } = useCollapseDrawer();

    return (
        <Box
            id={'dashboard-layout'}
            sx={{
                display: { lg: 'flex' },
                minHeight: { lg: 1 },
            }}
        >
            <DashboardHeader isCollapse={isCollapse} onOpenSidebar={() => { setOpen(true); if (isCollapse) onToggleCollapse() }} />
        
            <NavbarVertical isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />

            <MainStyle id={'main-style'} collapseClick={collapseClick}>
                <Outlet />
            </MainStyle>

            <ZendeskComp />
        </Box>
    );
}