import Page from "src/components/base/Page";
import { Grid, Pagination, Stack, Theme } from '@mui/material';
import queryString from 'query-string';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useApiCall } from 'src/hooks/useApiCall';
import { useLocation, useNavigate } from 'react-router-dom';
import { ProfileImageType } from 'src/@types/types';

import Searchbar from 'src/components/image-list/Searchbar';
import SortButtons from 'src/components/image-list/SortButtons';
import FilterButtions from 'src/components/image-list/FilterButtons';
import AddNewImgCard from 'src/components/image-list/AddNewImgCard';
import UploadPopover from 'src/components/image-list/UploadPopover';
import MainDashImgHandler from 'src/components/image-list/MainDashImgHandler';

import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
// import GppMaybeIcon from '@mui/icons-material/GppMaybe';
import GppBadIcon from '@mui/icons-material/GppBad';
import SafetyCheckIcon from '@mui/icons-material/SafetyCheck';
import GroupWorkIcon from '@mui/icons-material/GroupWork';
import LoadingBackdrop from 'src/components/base/LoadingBackdrop';
import SignupStepper from "src/components/signup/SignupStepper";

// ----------------------------------------------------------------------

type StatusChoice = {
    name: string;
    icon: JSX.Element;
    display_name: string | null;
    mobile_name: string | null;
    color: keyof Theme['palette'] | '';  // Using keyof to restrict color to valid palette keys
};

export const STATUS_CHOICES: StatusChoice[] = [
    { name: 'all', display_name: 'All My Photos', mobile_name: 'All', icon: <GroupWorkIcon />, color: '' },
    { name: 'pending', display_name: 'Photos Pending Report', mobile_name: 'Pending', icon: <SafetyCheckIcon />, color: '' },
    { name: 'protected', display_name: 'Photos For Which No Possible Abuses Found', mobile_name: 'Protected', icon: <VerifiedUserIcon />, color: 'success' },
    { name: 'abused', display_name: 'Photos For Which Possible Abuses Have Been Found', mobile_name: 'Abused', icon: <GppBadIcon />, color: 'error' },

    // We show error images to the user as simply being protected.
    { name: 'error', display_name: null, mobile_name: null, icon: <VerifiedUserIcon />, color: 'primary' }
]

export default function Home() {

    const location = useLocation();
    const navigate = useNavigate();
    const { apiCall } = useApiCall();

    const queryParams: queryString.ParsedQuery = queryString.parse(location.search);
    const queryParamsRef = useRef(queryParams);
    queryParamsRef.current = queryParams;

    const uploaded_images_previously = (localStorage.getItem('uploadedImages') || '') === 'true';

    const [uploadOpen, setUploadOpen] = useState(false);
    const [images, setImages] = useState<ProfileImageType[]>([]);
    const [totalPages, setTotalPages] = useState<number>(0);
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [searchTerm, setSearchTerm] = useState<string>(queryParams.name as string || '');
    const [awaitingResponse, setAwaitingResponse] = useState<boolean>(false);

    useEffect(() => {
        // Safely update searchTerm based on the URL's query parameter
        setSearchTerm(typeof queryParams.name === 'string' ? queryParams.name : '');
    }, [queryParams.name]);

    // Helper to update query parameters in the URL
    const updateQueryParams = useCallback((updates: Record<string, string | undefined>) => {
        const queryParams = queryString.parse(location.search);
        const newParams = { ...queryParams, ...updates };
        navigate(`${location.pathname}?${queryString.stringify(newParams)}`, { replace: true });
    }, [location, navigate]);

    // Fetch images based on current filters in the URL
    const fetchImages = useCallback(() => {
        setAwaitingResponse(true);
        const queryParams = queryString.parse(location.search);
        let url = 'get_images';
        if (Object.keys(queryParams).length > 0) {
            url += `?${queryString.stringify(queryParams)}`;
        }
        apiCall(url, 'GET', null, (data) => {
            setTotalPages(data.total_pages);
            setCurrentPage(data.current_page);
            setImages(data.results);
            if (data.results.length > 0 && !uploaded_images_previously) {
                localStorage.setItem('uploadedImages', 'true');
            }
        }).then(() => {
            setAwaitingResponse(false);
        });
    }, [location.search, uploaded_images_previously, apiCall]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => { fetchImages() }, [location.search]);

    const handlePageChange = (page: number) => {
        updateQueryParams({ page: page.toString() });
    };

    return (
        <Page title="Home">
            <Grid container spacing={3} sx={{ display: 'flex', justifyContent: 'center' }}>
                {(images.length === 0 && !uploaded_images_previously) &&
                    <Grid item xs={12}>
                        <SignupStepper activeStep={3} />
                    </Grid>
                }
                {awaitingResponse && <LoadingBackdrop />}
                <UploadPopover open={uploadOpen} setOpen={setUploadOpen} />
                <FilterButtions updateQueryParams={updateQueryParams} />
                <SortButtons updateQueryParams={updateQueryParams} />
                <Searchbar
                    queryParams={queryParams}
                    queryParamsRef={queryParamsRef}
                    searchTerm={searchTerm}
                    setSearchTerm={setSearchTerm}
                />
                {currentPage === 1 &&
                    <AddNewImgCard setUploadOpen={setUploadOpen} />
                }

                {images.map((image) => (
                    <MainDashImgHandler
                        key={image.id}
                        image={image}
                    />
                ))}

                <Grid item xs={12}>
                    <Stack direction="row" spacing={2} justifyContent="center">
                        <Pagination
                            count={totalPages}
                            page={currentPage}
                            onChange={(event, page) => { handlePageChange(page) }}
                            showFirstButton
                            showLastButton
                            color="primary"
                            disabled={totalPages <= 1}
                        />
                    </Stack>
                </Grid>
            </Grid>
        </Page>
    )
}