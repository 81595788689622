import { Button, Grid, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import LoadingBackdrop from "src/components/base/LoadingBackdrop";
import Page from "src/components/base/Page";
import AddressForm from "src/components/signup/AddressForm";
import { useApiCall } from "src/hooks/useApiCall";
import useAuth from "src/hooks/useAuth";

// ----------------------------------------------------------------------

export default function Account() {

    const { apiCall } = useApiCall();
    const { currentProfile } = useAuth();

    const [firstName, setFirstName] = useState<string>(currentProfile?.user.first_name || '');
    const [lastName, setLastName] = useState<string>(currentProfile?.user.last_name || '');
    const [email, setEmail] = useState<string>(currentProfile?.user.email || '');
    const [birthday, setBirthday] = useState<string>(currentProfile?.birthday || '');
    const [password, setPassword] = useState<string>('');
    const [oldPassword, setOldPassword] = useState<string>('');
    const [confirmPassword, setConfirmPassword] = useState<string>('');
    const [buttonDisabled, setButtonDisabled] = useState<boolean>(true);

    useEffect(() => {
        if (
            (firstName !== currentProfile?.user.first_name && firstName.length > 0) ||
            (lastName !== currentProfile?.user.last_name && lastName.length > 0) ||
            (email !== currentProfile?.user.email && email.length > 0) ||
            (birthday !== currentProfile?.birthday && birthday.length > 0)
        ) {
            setButtonDisabled(false);
        } else {
            setButtonDisabled(true);
        }
    }, [firstName, lastName, email, birthday, currentProfile]);

    const [awaitingResponse, setAwaitingResponse] = useState<boolean>(false);

    const saveChanges = () => {
        setAwaitingResponse(true);
        const getFormData = () => {
            const formData = new FormData();

            if (firstName !== currentProfile?.user.first_name) {
                formData.append('first_name', firstName);
            }
            if (lastName !== currentProfile?.user.last_name) {
                formData.append('last_name', lastName);
            }
            if (email !== currentProfile?.user.email) {
                formData.append('email', email);
            }
            if (birthday !== currentProfile?.birthday && birthday.length > 0) {
                formData.append('birthday', birthday);
            }
            if (password) {
                formData.append('new_password', password);
            }
            if (oldPassword) {
                formData.append('old_password', oldPassword);
            }
            if (confirmPassword) {
                formData.append('confirm_password', confirmPassword);
            }
            return formData;
        };
        apiCall(`edit_profile/${currentProfile?.id}`, "POST", getFormData(), (data) => {
            console.log(data);
            window.location.reload();
        });
    };

    return (
        <Page title="Account">
            {awaitingResponse && <LoadingBackdrop />}
            <Stack spacing={1} width={'100%'} alignItems={'center'} justifyContent={'center'}>
                <AddressForm
                    isEdit
                    firstName={firstName}
                    setFirstName={setFirstName}
                    lastName={lastName}
                    setLastName={setLastName}
                    email={email}
                    setEmail={setEmail}
                    birthday={birthday}
                    setBirthday={setBirthday}
                    password={password}
                    setPassword={setPassword}
                    oldPassword={oldPassword}
                    setOldPassword={setOldPassword}
                    confirmPassword={confirmPassword}
                    setConfirmPassword={setConfirmPassword}
                    setButtonDisabled={setButtonDisabled}
                    sx={{ pr: 2 }}
                />
                <Grid container spacing={3} sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Grid item xs={6}>
                        <Button
                            color="primary"
                            variant="contained"
                            disabled={buttonDisabled}
                            onClick={saveChanges}
                            fullWidth
                        >
                            Save
                        </Button>
                    </Grid>
                </Grid>
            </Stack>
        </Page>
    )
}