import { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import { PATH_AFTER_LOGIN } from 'src/config';
import useAuth from 'src/hooks/useAuth';

// ----------------------------------------------------------------------

type GuestGuardProps = {
  children: ReactNode;
};

export default function GuestGuard({ children }: GuestGuardProps) {
  const { isAuthenticated } = useAuth();

  if (isAuthenticated) {
    return <Navigate to={PATH_AFTER_LOGIN} />;
  } else {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
  }

  return <>{children}</>;
}
