import { useEffect, useState } from 'react';
import Page from 'src/components/base/Page';
import { useApiCall } from 'src/hooks/useApiCall';
import { StripeInvoiceType } from 'src/@types/types';
import LoadingBackdrop from 'src/components/base/LoadingBackdrop';
import { alpha, Fade, Grid, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography, useTheme } from '@mui/material';
import { formatDate } from 'src/utils/formatDate';
import { capWords } from 'src/utils/capWords';
import Iconify from 'src/components/base/Iconify';
import { fCurrency } from 'src/utils/formatNumber';

// ----------------------------------------------------------------------

export default function BillingHistory() {

    const { apiCall } = useApiCall();

    const [awaitingResponse, setAwaitingResponse] = useState<boolean>(false);
    const [invoices, setInvoices] = useState<StripeInvoiceType[]>();

    useEffect(() => {
        const getInvoices = () => {
            setAwaitingResponse(true);
            apiCall('get_invoices', "GET", null, (data) => {
                setInvoices(data);
            }).then(() => setAwaitingResponse(false));
        };
        getInvoices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const theme = useTheme();

    return (
        <Page title="Billing History">
            {awaitingResponse && <LoadingBackdrop />}
            <Grid container spacing={3} sx={{ display: 'flex', justifyContent: 'center' }}>
                {(!invoices || invoices?.length === 0) && !awaitingResponse &&
                    <Grid item><Typography>No Invoices Found</Typography></Grid>
                }
                {invoices && invoices?.length > 0 &&
                    <Grid item xs={12}>
                        <TableContainer sx={{ width: '100%' }}>
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Transaction Date</TableCell>
                                        <TableCell>Subscription Schedule</TableCell>
                                        <TableCell>Valid Through</TableCell>
                                        <TableCell>Payment Method</TableCell>
                                        <TableCell>Amount</TableCell>
                                        <TableCell align="right">Invoice</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody sx={{ '& > :nth-of-type(2n+2)': { bgcolor: alpha(theme.palette.primary.main, 0.05) } }}>
                                {invoices?.map((row) => (
                                    <TableRow key={row.stripe_invoice_id}>
                                        <TableCell>{formatDate(row.created)}</TableCell>
                                        <TableCell>{capWords(row.price.type)}</TableCell>
                                        <TableCell>{formatDate(row.period_end)}</TableCell>
                                        {row.card_brand && row.last_4 ?
                                            <TableCell>{capWords(row.card_brand) + `⠀••••${row.last_4}`} </TableCell> :
                                            <TableCell>-</TableCell>
                                        }
                                        <TableCell>{fCurrency(row.amount_paid)}</TableCell>
                                        <TableCell align="right">
                                            <Tooltip
                                                title="Download PDF"
                                                arrow
                                                enterDelay={500}
                                                enterNextDelay={500}
                                                enterTouchDelay={500}
                                                TransitionComponent={Fade}
                                                TransitionProps={{ timeout: 600 }}
                                            >
                                                <IconButton
                                                    size={'small'}
                                                    color={'primary'}
                                                    onClick={() => {
                                                        window.open(row.invoice_pdf, '_blank');
                                                    }}
                                                >
                                                    <Iconify icon={'eva:download-outline'} />
                                                </IconButton>
                                            </Tooltip>
                                        </TableCell>
                                    </TableRow>
                                ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                }
            </Grid>
        </Page>
    );
}