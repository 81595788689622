import { Box, Grid, IconButton, Input, InputAdornment } from "@mui/material";
import Iconify from "../base/Iconify";
import { useLocation, useNavigate } from "react-router-dom";
import queryString from 'query-string';
import { MutableRefObject, useCallback } from "react";
import { debounce } from 'lodash';

// ----------------------------------------------------------------------

type SearchbarProps = {
    queryParams: queryString.ParsedQuery;
    queryParamsRef: MutableRefObject<queryString.ParsedQuery>;
    searchTerm: string;
    setSearchTerm: (value: string) => void;
};

export default function Searchbar({ queryParams, queryParamsRef, searchTerm, setSearchTerm }: SearchbarProps) {

    const location = useLocation();
    const navigate = useNavigate();

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const nextValue = event.target.value;
        setSearchTerm(nextValue);
        debouncedSearch(nextValue);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedSearch = useCallback(debounce((nextValue) => {
        const currentParams = queryParamsRef.current;
        const newParams = { ...currentParams, ref_id: nextValue.trim() ? nextValue : undefined };
        navigate(`${location.pathname}?${queryString.stringify(newParams)}`);
    }, 750), [navigate, location.pathname]);

    const handleClearSearch = () => {
        setSearchTerm('');
        const { ref_id, ...rest } = queryParams;
        navigate(`${location.pathname}?${queryString.stringify(rest)}`);
    };

    return (
        <Grid item xs={12} sm={12} md={12} lg={5}>
            <Box sx={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'end' }}>
                <Input
                    fullWidth
                    placeholder="Search…"
                    startAdornment={
                        <InputAdornment position="start">
                            <Iconify
                                icon={'eva:search-fill'}
                                sx={{ color: 'text.disabled', width: 20, height: 20 }}
                            />
                        </InputAdornment>
                    }
                    endAdornment={
                        <>
                            {searchTerm.length > 0 &&
                                <InputAdornment position="end">
                                    <IconButton onClick={handleClearSearch}>
                                        <Iconify
                                            icon={'eva:close-fill'}
                                            sx={{ color: 'text.disabled', width: 20, height: 20 }}
                                        />
                                    </IconButton>
                                </InputAdornment>
                            }
                        </>
                    }
                    value={searchTerm}
                    onChange={handleChange}
                    sx={{ mr: 1 }}
                />
            </Box>
        </Grid>
    )
}