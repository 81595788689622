import { createContext, ReactNode, useEffect, useReducer } from 'react';
// utils
import axios from '../utils/axios';
import { getProfile, isValidToken, refreshAccessToken, setSession } from '../utils/jwt';
// @types
import { ActionMap, AuthState, AuthProfile, JWTContextType, initializeProps } from '../@types/auth';
import { MAIN_API } from '../config';

// ----------------------------------------------------------------------

enum Types {
  Initial = 'INITIALIZE',
  Login = 'LOGIN',
  Logout = 'LOGOUT',
}

type JWTAuthPayload = {
  [Types.Initial]: {
    isAdmin: boolean;
    isAuthenticated: boolean;
    profile: AuthProfile;
    isPaid: boolean;
  };
  [Types.Login]: {
    isAdmin: boolean;
    profile: AuthProfile;
    isPaid: boolean;
  };
  [Types.Logout]: undefined;
};

export type JWTActions = ActionMap<JWTAuthPayload>[keyof ActionMap<JWTAuthPayload>];

const initialState: AuthState = {
  isAdmin: false,
  isAuthenticated: false,
  isInitialized: false,
  isPaid: false,
  profile: null,
};

const JWTReducer = (state: AuthState, action: JWTActions) => {
  switch (action.type) {
    case 'INITIALIZE':
      return {
        isAdmin: action.payload.isAdmin,
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: true,
        isPaid: action.payload.isPaid,
        profile: action.payload.profile,
      };
    case 'LOGIN':
      return {
        ...state,
        isAuthenticated: true,
        profile: action.payload.profile,
        isPaid: action.payload.isPaid,
        isAdmin: action.payload.isAdmin,
      };
    case 'LOGOUT':
      return {
        ...state,
        isAuthenticated: false,
        profile: null,
      };

    default:
      return state;
  }
};

const AuthContext = createContext<JWTContextType | null>(null);

// ----------------------------------------------------------------------

type AuthProviderProps = {
  children: ReactNode;
};

function AuthProvider({ children }: AuthProviderProps) {
  const [state, dispatch] = useReducer(JWTReducer, initialState);

  const initialize = async ({ access, refresh }: initializeProps) => {
    try {
      const accessToken = access?.length ? access : window.localStorage.getItem('accessToken');
      const refreshToken = refresh?.length ? refresh : window.localStorage.getItem('refreshToken');

      if (accessToken && isValidToken(accessToken)) {
        setSession(accessToken, refreshToken);
        const profile = await getProfile(accessToken);

        dispatch({
          type: Types.Initial,
          payload: {
            isAdmin: profile.user.is_staff,
            isAuthenticated: true,
            isPaid: ['active', 'trial', 'canceling'].includes(profile.account_status),
            profile,
          },
        });
      } else {
        if (refreshToken && isValidToken(refreshToken)) {
          const { access, refresh } = await refreshAccessToken(refreshToken);
          if (access && refresh && isValidToken(access) && isValidToken(refresh)) {
            initialize({ access, refresh });
          } else {
            dispatch({
              type: Types.Initial,
              payload: {
                isAdmin: false,
                isAuthenticated: false,
                isPaid: false,
                profile: null,
              },
            });
          }
        } else {
          dispatch({
            type: Types.Initial,
            payload: {
              isAdmin: false,
              isAuthenticated: false,
              isPaid: false,
              profile: null,
            },
          });
        }
      }
    } catch (err) {
      console.error(err);
      dispatch({
        type: Types.Initial,
        payload: {
          isAdmin: false,
          isAuthenticated: false,
          isPaid: false,
          profile: null,
        },
      });
    }
  };

  useEffect(() => {
    initialize({ access: '', refresh: '' });
  }, []);

  const login = async (email: string, password: string) => {
    const res =  await new Promise(async (resolve, reject) => {
      try {
        await axios.post(MAIN_API.base_url + 'token_obtain/', {
          email,
          password,
        }).then(async (response) => {
          if (response.status === 200) {
            const { access, refresh } = response.data;
            setSession(access, refresh);
            const profile = await getProfile(access);
            dispatch({
              type: Types.Login,
              payload: {
                isAdmin: profile.user.is_staff,
                isPaid: ['active', 'trial', 'canceling'].includes(profile.account_status),
                profile,
              },
            });
            resolve(response.data);
          } else {
            reject({ description: JSON.stringify(response.data) });
          }
        })
      } catch (err) {
        if (JSON.stringify(err).includes('Unable to log in with provided credentials')) {
          reject('Invalid email or password');
        } else {
          reject(JSON.stringify(err));
        }
      }
    });
    return res;
  };

  const register = async (userData: FormData) => {
    try {
      const response = await axios.post(`${MAIN_API.base_url}register/`, userData);
      if (response.status === 201 || response.status === 200) {
        return { success: true, detail: 'Account created.' };
      } else if (response.status === 400) {
        const res_msg: string = response.data.detail || '';
        return { success: false, detail: res_msg };
      } else {
        return { success: false, detail: 'Unknown error' };
      }
    } catch (error) {
      return { success: false, detail: error.detail };
    }
  };

  const logout = async () => {
    window.sessionStorage.clear();
    window.localStorage.clear();
    setSession(null, null);
    dispatch({ type: Types.Logout });
  };



  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        login,
        register,
        logout,
        initialize,
        currentProfile: state.profile,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };