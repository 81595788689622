export function formatDate(dateString?: string): string {
  if (!dateString) {
      return "-";
  }

  // Attempt to parse the date string
  const date = new Date(dateString);

  // Check if date is valid
  if (isNaN(date.getTime())) {
      return "-";
  }

  // Format the date as MM-DD-YYYY in UTC
  const day = date.getUTCDate().toString().padStart(2, '0');
  const month = (date.getUTCMonth() + 1).toString().padStart(2, '0'); // Month is 0-indexed
  const year = date.getUTCFullYear();

  return `${month}-${day}-${year}`;
}