import { Box, Link, Stack, Typography } from '@mui/material';
import { XDialog } from '../base/XDialog';
import { TRUSTE_SEAL_URL } from 'src/config';

// ----------------------------------------------------------------------

type SecurityPrivacyProps = {
    open: boolean;
    setOpen: (value: boolean) => void;
};

export default function SecurityPrivacy({ open, setOpen }: SecurityPrivacyProps) {

    return (
        <XDialog
            open={open}
            onClose={() => setOpen(false)}
            title="Security and Privacy"
        >
            <Stack spacing={2}>
                <Typography variant="h4" gutterBottom>
                    Security and Privacy
                </Typography>
                <Stack spacing={2}>
                    <Typography variant="body1" paragraph>
                        ImageShield is committed to protecting your data and privacy. We have implemented strict security measures to ensure that your data is protected at all times.
                    </Typography>
                    <Typography variant="body1" paragraph>
                        Our security policy is designed to protect your data from unauthorized access, disclosure, alteration, and destruction. We use industry-standard encryption and security protocols to protect your data.
                    </Typography>
                    <Typography variant="body1" paragraph>
                        ImageShield personnel cannot see the photographs or other images you upload to the service. Only you can see them, in your Gallery, after logging into your account. We can only identify your photographs by numbers we've assigned to them. So if you ever need to contact us about a particular photograph, we'll ask you to tell us which number photograph you're referring to, and we'll tell you where to find that number in your Gallery of images.
                    </Typography>
                    <Typography variant="body1" paragraph>
                        ImageShield does not store the credit card numbers or other financial information provided by our subscribers. We only store transaction IDs, so that if in the future we need to issue a partial or full refund to a subscriber we need only submit the original transaction ID to our credit card payment processor.
                        We do store account information, such as subscribers' names and email addresses, but only specific, authorized senior personnel at ImageShield will ever have access to that information.
                    </Typography>
                    <Typography variant="body1" paragraph>
                        Our security practices, policies and procedures have been reviewed and validated by <Link href={"https://trustarc.com/consumer-information/privacy-certification-standards/"}>TrustArc</Link>, and you'll find the TrustArc seal of approval in various places through our website and application.
                    </Typography>
                    <Typography variant="body1" paragraph>
                        If you have any questions or concerns about our security and privacy policy, please contact us at <Link href="mailto:">support@imageshield.com</Link>.
                    </Typography>

                    <Stack justifyContent={'center'} alignItems={'center'} sx={{ pt: 2 }}>
                        <Box>
                            <img src={TRUSTE_SEAL_URL} alt="TRUSTe" />
                        </Box>
                    </Stack>
                </Stack>
            </Stack>
        </XDialog>
    )
}