// routes
import { PATH_PAGE } from './routes/paths';

// API
// ----------------------------------------------------------------------

export const HOST_API = process.env.REACT_APP_HOST_API_KEY || '';

export const MAIN_API = {
  // base_url: "http://localhost:8080/",
  // base_url: "https://is-backend-test-1.fly.dev/"
  base_url: "https://isback.fly.dev/"
};

export const SOCIALS = {
  facebook: "https://www.facebook.com/profile.php?id=100064076020962",
  x: "https://x.com/image_shield",
  instagram: "https://www.instagram.com/image_shield/?hl=en"
};

export const LANDING_PAGES = {
  home: "https://www.imageshield.com/home",
  about: "https://www.imageshield.com/About"
}

export const MAIN_LOGO = "https://is-assets-v2.s3.amazonaws.com/assets/is_logo_main.png";
export const TRUSTE_SEAL_URL = "//privacy-policy.truste.com/privacy-seal/seal?rid=87419b93-285d-4d77-946b-bcd0ae23b547"

export const ZENDESK_KEY = process.env.REACT_APP_ZENDESK_KEY || '';

// ----------------------------------------------------------------------

export const PATH_AFTER_LOGIN = PATH_PAGE.photos;

// ----------------------------------------------------------------------

export const HEADER = {
  MOBILE_HEIGHT: 64,
  MAIN_DESKTOP_HEIGHT: 68,
  DASHBOARD_DESKTOP_HEIGHT: 72,
  DASHBOARD_DESKTOP_OFFSET_HEIGHT: 72 - 32,
};

export const NAVBAR = {
  BASE_WIDTH: 260,
  DASHBOARD_WIDTH: 280,
  DASHBOARD_COLLAPSE_WIDTH: 88,
  //
  DASHBOARD_ITEM_ROOT_HEIGHT: 48,
  DASHBOARD_ITEM_SUB_HEIGHT: 40,
  DASHBOARD_ITEM_HORIZONTAL_HEIGHT: 32,
};

export const ICON = {
  NAVBAR_ITEM: 22,
  NAVBAR_ITEM_HORIZONTAL: 20,
};

export const TRANSITIONS = {
  accordion: '0.5s background-color;',
  text: '0.5s color;',
};