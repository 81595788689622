import { Box, Card, CardMedia } from '@mui/material';
import { useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { ProfileImageType } from 'src/@types/types';
import { STATUS_CHOICES } from 'src/pages/customer_pages/Photos';

// ----------------------------------------------------------------------

type ImageCardProps = {
    image: ProfileImageType;
    cardActions: JSX.Element;
    imageClick?: () => void;
    preview?: boolean;
}

export default function ImageCard({ image, cardActions, imageClick, preview }: ImageCardProps) {

    const theme = useTheme();

    const max_height = 400;
    const min_height = 200;

    const [imageHeight, setImageHeight] = useState<number>(min_height);

    useEffect(() => {
        const img = new Image();
        img.src = image.img_url;
        img.onload = () => {
            const aspectRatio = img.width / img.height;
            const calculatedHeight = Math.max(min_height, Math.min(max_height, max_height / aspectRatio));
            setImageHeight(calculatedHeight);
        };
    }, [image.img_url]);

    let statusColor: any = STATUS_CHOICES.find((status) => status.name === image.status.toLowerCase())?.color || 'text';
    if (statusColor !== 'text') { statusColor = statusColor + '.main' };

    return (
        <Card sx={{ width: '100%', position: 'relative' }}>
            <CardMedia
                sx={{
                    height: preview ? min_height : imageHeight,
                    objectFit: 'cover',
                    '&:hover': {
                        filter: 'brightness(0.9)',
                        cursor: 'pointer',
                    }
                }}
                image={image.img_url}
                title={image.admin_ref_id}
                onClick={imageClick}
            />
            <Box
                sx={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    color: statusColor !== 'text' ? statusColor : theme.palette.text.disabled,
                    borderBottomLeftRadius: 16,
                    borderTopRightRadius: 4,
                    paddingBottom: 0.15,
                    paddingTop: 0.5,
                    paddingX: 0.75,
                    backgroundColor: theme.palette.background.paper,
                    opacity: 0.65
                }}
            >
                {STATUS_CHOICES.find((status) => status.name === image.status.toLowerCase())?.icon}
            </Box>
            {cardActions}
        </Card>
    )
}